/* eslint-disable import/no-cycle */
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  ADD_TAX_DECLARATION_YEAR,
  DELETE_TAX_DECLARATION_FORM,
  RESET_TAX_DECLARATION_VIEW_STATE,
  VIEW_TAX_DECLARATION_FORM,
  SET_TAX_DECLARATION_FORM_STATUS,
  SET_TAX_DECLARATION_FORM_DESCRIPTION,
  SET_TAX_DECLARATION_FORMS,
  SET_TAX_DECLARATION_DEFS,
  SET_TAX_DECLARATION_CONTENT_DEF,
  SET_TAX_DECLARATION_FORM_STATE,
  UPDATE_TAX_DECLARATION_FORM_FIELD,
  UPDATE_TAX_DECLARATION_FORM_DATA,
  SET_TAX_DECLARATION_YEAR_STATUSES,
  ADD_TAX_DECLARATION_YEAR_STATUS,
  RESET_TAX_DECLARATION_FORM_FIELD,
  UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD,
  SET_TAX_DECLARATION_DOCUMENTS,
  SET_TAX_DECLARATION_CONTENT_DEF_BULK,
  SET_TAX_CONNECTIONS_CHART,
  DELETE_TAX_DECLARATION_YEAR,
  SET_TAX_DECLARATION_YEAR_WITH_INFO,
  SET_TAX_DECLARATION_FORM_QUALITY_CHECK,
  UPDATE_TAX_DECLARATION_TABLE_ROW_CELL,
  ADD_TAX_DECLARATION_TABLE_ROW,
  DELETE_TAX_DECLARATION_TABLE_ROW,
  SET_TAX_OWNERS,
  RESET_TAX_DECLARATION_TABLE,
  TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE,
  UPDATE_TAX_DECLARATION_TABLE_ROWS,
  RESET_TAX_DECLARATION_TABLE_ROW,
  TOGGLE_TAX_DECLARATION_TABLE_ACTIVE,
  TOGGLE_TAX_DECLARATION_FIELD_ACTIVE,
  TOGGLE_TAX_DECLARATION_SECTION_ACTIVE,
} from 'redux/actionsTypes';
import {
  TaxDeclationFormState,
  FormStatus,
  TaxDeclarationForm,
} from 'redux/reducers/TaxDeclarationView/types';
import {
  FormContentDefinition,
  TaxDeclarationFormStructure,
  TaxDeclarationFormData,
  TaxDeclarationFormDefinition,
  getTaxDeclarationFormDataConfig,
} from '@agoy/tax-declaration-document';
// eslint-disable-next-line import/no-cycle
import { RootState } from 'redux/reducers';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import {
  currentDocuments,
  definitionOfForm,
  viewedFormState,
} from 'redux/reducers/TaxDeclarationView/selectors';
import {
  getInitialTaxDeclarationData,
  collectTaxViewValues,
} from 'utils/TaxDeclaration/initialData';
import { DocumentValues } from '_tax/types';
import {
  AgoyDocumentChanges,
  applyChanges,
  calculateReferences,
  updateValues,
} from '@agoy/document';
import { setDocumentValues } from '_tax/redux/document/actions';
import { setTaxViewState } from '_tax/redux/tax-view/actions';
import { PeriodStatus, Status } from '_shared/types';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { setAccountingBalances } from '_reconciliation/redux/accounting-view/actions';
import { mapAndGroupStoredForms } from 'utils/TaxDeclaration/mapping';
import { TaxDocumentAttachment } from '@agoy/tax-document';
import { formatYearVariable } from 'utils/document-util';
import { getClient } from '_clients/redux/customers/actions';
import { getForms } from '_tax/API/skv-forms';
import { ConnectionNode, ShareOwners } from '_client-connections/types';
import { format, compareAsc } from 'date-fns';
import { parse, parseFinancialYear } from '@agoy/dates';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import {
  AddTaxDeclarationYear,
  DeleteTaxDeclarationForm,
  ResetTaxDeclarationViewState,
  ViewTaxDeclarationForm,
  SetTaxDeclarationFormStatus,
  SetTaxDeclarationFormDescription,
  SetTaxDeclarationForms,
  SetTaxDeclarationFormDefinitions,
  SetTaxDeclarationFromContentDefinition,
  SetTaxDeclationFormState,
  UpdateTaxDeclarationFormDataAction,
  SetTaxDeclarationYearStatuses,
  AddTaxDeclarationYearStatus,
  SetTaxDeclarationDocuments,
  SetTaxDeclarationFromContentDefinitionBulk,
  SetConnectionsChart,
  DeleteTaxDeclarationYear,
  SetTaxDeclarationYearWithInfo,
  SetTaxDeclarationFormQualityCheck,
  SetOwners,
  TaxDeclarationViewActions,
} from './types';

const isConnectingDocumentEnabled = (
  definition: TaxDeclarationFormStructure
): boolean => {
  // The feature is enabled when both the FE feature flag is on
  // and the document structure actually defines external documents.
  return !!Object.values(definition?.children || []).find(
    (def) =>
      def.type === 'externalDocument' ||
      def.type === 'externalDocuments' ||
      def.type === 'relatedClient'
  );
};

export const resetTaxDeclarationViewState =
  (): ResetTaxDeclarationViewState => ({
    type: RESET_TAX_DECLARATION_VIEW_STATE,
  });

export const addTaxDeclarationYear = (
  financialYear: string
): AddTaxDeclarationYear => ({
  type: ADD_TAX_DECLARATION_YEAR,
  financialYear,
});

export const deleteTaxDeclarationFormAction = (
  financialYear: string,
  id: string
): DeleteTaxDeclarationForm => ({
  type: DELETE_TAX_DECLARATION_FORM,
  financialYear,
  id,
});

export const updateTaxDeclarationFormData = (
  formId: string,
  document: TaxDeclarationFormData
): UpdateTaxDeclarationFormDataAction => ({
  type: UPDATE_TAX_DECLARATION_FORM_DATA,
  formId,
  document,
});

export const deleteTaxDeclarationForm =
  (
    financialYear: string,
    formId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const customerId = getState().customerView.currentCustomer;
      if (customerId) {
        const deleteResult = await asResultClass(
          getApiSdk(getContext()).deleteAgoyDocument({
            documentId: formId,
            clientId: customerId,
          })
        );
        if (deleteResult.err) {
          throw new Error('Failed to delete form');
        }
        dispatch(deleteTaxDeclarationFormAction(financialYear, formId));
      }
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

export const addTaxDeclarationForm =
  (
    financialYear: string,
    formType: string,
    relatedClients: Record<string, string>
  ): ThunkAction<
    Promise<string | undefined>,
    RootState,
    unknown,
    Action<string>
  > =>
  async (dispatch, getState) => {
    try {
      const customerId = getState().customerView.currentCustomer;
      const selectedYear = getState().taxDeclarationView.years[financialYear];

      const { definitions } = selectedYear || {};
      const definition = definitions?.[formType];
      if (customerId && definition) {
        let parentId: string | undefined;
        if (definition.category === 'sub') {
          // Only one main form supported at the moment,
          // but there can be forms of type 'other' on the same level
          const mainForm = definitions
            ? selectedYear?.forms.find(
                // how the category here is related to the document id?
                (form) => definitions[form.type]?.category === 'main'
              )
            : undefined;

          parentId = mainForm?.id;
          if (!parentId) {
            throw new Error('No parent for subform');
          }
        } else if (definition.category === 'helper') {
          const formTypeId = definition.typeId || '';
          let parentType = '';
          Object.values(definitions).forEach((def) => {
            const subFormIds = def?.subFormsIds;

            if (subFormIds?.includes(formTypeId)) {
              parentType = def?.type || '';
            }
          });

          const mainForm = selectedYear?.forms.find(
            // how the category here is related to the document id?
            (form) => definitions[form.type]?.category === 'main'
          );

          mainForm?.subForms?.forEach((subForm) => {
            if (subForm.type === parentType) {
              parentId = subForm.id;
            }
          });

          if (!parentId) {
            throw new Error('No parent for subform');
          }
        }

        const addResult = await asResultClass(
          getApiSdk(getContext()).createAgoyDocument({
            clientId: customerId,
            requestBody: {
              name: definition.name,
              financialYear,
              type: formType,
              parentId,
              relatedClients,
            },
          })
        );
        if (addResult.err) {
          throw new Error(`Failed to add new form ${addResult.val}`);
        }

        trackCustomEvent({
          eventCategory: 'annual_accounts_taxes:declarations',
          eventAction: 'tax_file:add',
          eventName: `type:${definition.name}`,
        });

        const updateResult = await asResultClass(
          getApiSdk(getContext()).getTaxDeclarationDocuments({
            clientId: customerId,
            financialYear,
          })
        );
        if (updateResult.err) {
          throw new Error(
            `Failed to update after add new form ${addResult.val}`
          );
        }
        dispatch(
          setTaxDeclarationForms(
            financialYear,
            mapAndGroupStoredForms(updateResult.val)
          )
        );

        return addResult.val.id;
      }
    } catch (e) {
      console.error(e);
      dispatch(addGlobalErrorMessage('error'));
    }
    return Promise.resolve('');
  };

export const viewTaxDeclarationForm = (
  formId: string | null
): ViewTaxDeclarationForm => ({
  type: VIEW_TAX_DECLARATION_FORM,
  formId,
});

export const setTaxDeclarationFormStatus =
  (
    formId: string,
    selectedYear: string,
    status: FormStatus
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const clientId = getState().customerView.currentCustomer;
      if (clientId) {
        const sdk = getApiSdk(getContext());
        const result = await asResultClass(
          sdk.setAgoyDocumentStatus({
            documentId: formId,
            clientId,
            requestBody: { status },
          })
        );

        if (result.ok) {
          dispatch(
            setTaxDeclarationFormStatusAction(formId, selectedYear, status)
          );
        }

        if (result.err) {
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    } catch (e) {
      console.error(e);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

export const setTaxDeclarationFormDescriptionAction = (
  formId: string,
  financialYear: string,
  description: string | null
): SetTaxDeclarationFormDescription => ({
  type: SET_TAX_DECLARATION_FORM_DESCRIPTION,
  formId,
  financialYear,
  description,
});

export const setTaxDeclarationFormDescription =
  (
    formId: string,
    selectedYear: string,
    description: string | null
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const clientId = getState().customerView.currentCustomer;
      if (clientId) {
        const sdk = getApiSdk(getContext());
        const result = await asResultClass(
          sdk.setAgoyDocumentDescription({
            documentId: formId,
            clientId,
            requestBody: { description },
          })
        );

        if (result.ok) {
          dispatch(
            setTaxDeclarationFormDescriptionAction(
              formId,
              selectedYear,
              description
            )
          );
        }

        if (result.err) {
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    } catch (e) {
      console.error(e);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

export const setTaxDeclarationFormQualityCheckAction = (
  formId: string,
  financialYear: string,
  checked: boolean
): SetTaxDeclarationFormQualityCheck => ({
  type: SET_TAX_DECLARATION_FORM_QUALITY_CHECK,
  formId,
  financialYear,
  checked,
});

export const setTaxDeclarationFormStatusAction = (
  formId: string,
  financialYear: string,
  status: FormStatus
): SetTaxDeclarationFormStatus => ({
  type: SET_TAX_DECLARATION_FORM_STATUS,
  formId,
  financialYear,
  status,
});

export const setTaxDeclarationForms = (
  financialYear: string,
  forms: TaxDeclarationForm[]
): SetTaxDeclarationForms => ({
  type: SET_TAX_DECLARATION_FORMS,
  financialYear,
  forms,
});

export const setTaxDeclarationFormDefintions = (
  financialYear: string,
  definitions: TaxDeclarationFormDefinition[]
): SetTaxDeclarationFormDefinitions => ({
  type: SET_TAX_DECLARATION_DEFS,
  financialYear,
  definitions,
});

export const setTaxDeclarationFormContentDefinitions = (
  financialYear: string,
  content: FormContentDefinition
): SetTaxDeclarationFromContentDefinition => ({
  type: SET_TAX_DECLARATION_CONTENT_DEF,
  financialYear,
  content,
});

export const setTaxDeclarationFormContentDefinitionsBulk = (
  financialYear: string,
  definitions: FormContentDefinition[]
): SetTaxDeclarationFromContentDefinitionBulk => ({
  type: SET_TAX_DECLARATION_CONTENT_DEF_BULK,
  financialYear,
  definitions,
});

export const fetchTaxDeclarationFormData =
  (
    financialYear: string,
    formId: string,
    formClientId: string,
    person?: boolean
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const { currentCustomer } = getState().customerView;
      const gettingDataForChildCompany = formClientId !== currentCustomer;
      const form = definitionOfForm(
        getState(),
        formId,
        formClientId,
        gettingDataForChildCompany
      );
      if (!form) {
        throw new Error(`Missing definition of form ${form}`);
      }

      if (!currentCustomer) {
        throw new Error('No current customer');
      }

      let annualReportValues =
        getState().document[formClientId]?.[financialYear]?.annualReport;
      let taxCalculationValues =
        getState().document[formClientId]?.[financialYear]?.taxCalculation;

      const sieDataState =
        getState().accountingView.clients[formClientId]?.years[financialYear];

      if (
        (!annualReportValues || !taxCalculationValues) &&
        (!person || gettingDataForChildCompany)
      ) {
        if (!getState().customers[formClientId]) {
          await dispatch(getClient(formClientId));
        }

        const [annualReport, taxView, accountingBalancesResult] =
          await getInitialTaxDeclarationData(
            getState().customers[formClientId],
            financialYear
          );

        if (annualReport && taxView && accountingBalancesResult) {
          annualReportValues = annualReport;
          taxCalculationValues = collectTaxViewValues(taxView);

          if (!sieDataState) {
            const { accountingBalances, updatedAt } = accountingBalancesResult;
            dispatch(
              setAccountingBalances(
                formClientId,
                financialYear,
                accountingBalances,
                updatedAt
              )
            );
          }

          dispatch(setTaxViewState(formClientId, financialYear, taxView));

          dispatch(
            setDocumentValues(
              formClientId,
              financialYear,
              'annualReport',
              annualReportValues
            )
          );
          dispatch(
            setDocumentValues(
              formClientId,
              financialYear,
              'taxCalculation',
              taxCalculationValues
            )
          );
        }
      }

      const { start, end } = parseFinancialYear(financialYear);
      const financialYearStart = format(start, 'yyyyMMdd');
      const financialYearEnd = format(end, 'yyyyMMdd');
      const { organisation } = getState();

      const { initial, definition } = getTaxDeclarationFormDataConfig(
        form,
        person && !gettingDataForChildCompany
          ? getState().persons[formClientId]
          : getState().customers[formClientId],
        financialYearStart,
        financialYearEnd,
        organisation.name
      );

      // load existing changes to document
      const changesResult = await asResultClass(
        getApiSdk(getContext()).getAgoyDocumentChanges({
          documentId: formId,
          clientId: formClientId,
        })
      );

      if (changesResult.err) {
        console.error(
          `Error getting client form changes for ${financialYear} ${formId}`,
          changesResult.val
        );
        throw new Error(
          `Error getting client form changes for ${financialYear} ${formId}`
        );
      }
      const storedChanges = changesResult.val
        .changes as AgoyDocumentChanges<TaxDeclarationFormStructure>;
      const document = storedChanges
        ? applyChanges<TaxDeclarationFormStructure, TaxDeclarationFormData>(
            definition
          )(initial, storedChanges)
        : initial;

      dispatch(
        setTaxDeclarationFormState(formId, {
          changes: storedChanges || {},
          definition,
          initial,
          document: isConnectingDocumentEnabled(definition)
            ? initial
            : updateDocument(document, definition, [
                annualReportValues || {},
                taxCalculationValues || {},
              ]),
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

const updateDocument = (
  document: TaxDeclarationFormData,
  definition: TaxDeclarationFormStructure,
  externalValues: DocumentValues[]
): TaxDeclarationFormData => {
  if (isConnectingDocumentEnabled(definition)) {
    return document;
  }
  const references = calculateReferences(definition, document, externalValues);
  const update = updateValues(definition);
  return update(document, references);
};

export const updateDocumentValues = (
  getState: () => RootState,
  dispatch: (action) => void
) => {
  const documents = currentDocuments(getState());
  const form = viewedFormState(getState());
  const { viewFormId } = getState().taxDeclarationView;

  if (form && viewFormId) {
    if (isConnectingDocumentEnabled(form.definition)) {
      // Not doing calculations here
      return;
    }
    const formData = updateDocument(form.document, form.definition, [
      documents?.annualReport || {},
      documents?.taxCalculation || {},
    ]);

    if (formData !== form.document) {
      dispatch(updateTaxDeclarationFormData(viewFormId, formData));
    }
  }
};

export const setTaxDeclarationFormState = (
  formId: string,
  formData: TaxDeclationFormState
): SetTaxDeclationFormState => ({
  type: SET_TAX_DECLARATION_FORM_STATE,
  formId,
  formData,
});

const updateTaxDocument =
  (action: TaxDeclarationViewActions) => async (dispatch, getState) => {
    await dispatch(action);
    updateDocumentValues(getState, dispatch);
  };

export const updateTaxDeclarationFormField = (
  partId: string,
  id: string,
  value: string | number | boolean | undefined
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: UPDATE_TAX_DECLARATION_FORM_FIELD,
    partId,
    id,
    value,
  });

export const toggleTaxDeclarationFormFieldActive = (
  partId: string
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: TOGGLE_TAX_DECLARATION_FIELD_ACTIVE,
    partId,
  });

export const toggleTaxDeclarationFormSectionActive = (
  partId: string
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: TOGGLE_TAX_DECLARATION_SECTION_ACTIVE,
    partId,
  });

export const updateTaxDeclarationFormTableCell = (
  partId: string,
  id: string,
  value: string | number | boolean | undefined
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: UPDATE_TAX_DECLARATION_TABLE_ROW_CELL,
    partId,
    id,
    value,
  });

export const toggleTaxDeclarationFormTableRowActive = (
  partId: string
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE,
    partId,
  });

export const toggleTaxDeclarationFormTableActive = (
  partId: string
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: TOGGLE_TAX_DECLARATION_TABLE_ACTIVE,
    partId,
  });

export const addTaxDeclarationFormTableRow = (
  partId: string,
  id: string,
  newId?: string
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: ADD_TAX_DECLARATION_TABLE_ROW,
    partId,
    id,
    newId,
  });

export const deleteTaxDeclarationFormTableRow = (
  partId: string,
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: DELETE_TAX_DECLARATION_TABLE_ROW,
    partId,
    id,
  });

export const updateTaxDeclarationFormTableRows = (
  rows: {
    id: string;
    sortKey?: number;
    active?: boolean;
  }[]
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: UPDATE_TAX_DECLARATION_TABLE_ROWS,
    rows,
  });

export const resetTaxDeclarationFormTable = (
  partId: string,
  id: string
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: RESET_TAX_DECLARATION_TABLE,
    partId,
    id,
  });

export const resetTaxDeclarationFormField = (
  partId: string,
  id: string,
  fieldConfig: any
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: RESET_TAX_DECLARATION_FORM_FIELD,
    partId,
    id,
    fieldConfig,
  });

export const resetTaxDeclarationTableRow = (
  partId: string,
  id: string,
  fieldConfig: any
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: RESET_TAX_DECLARATION_TABLE_ROW,
    partId,
    id,
    fieldConfig,
  });

export const updateTaxDeclarationFormExternalField = (
  partId: string,
  id: string,
  value: string | number | boolean | undefined
): ThunkAction<void, RootState, unknown, Action<string>> =>
  updateTaxDocument({
    type: UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD,
    partId,
    id,
    value,
  });

export const addTaxDeclarationYearStatusAction = (
  financialYear: string,
  status: PeriodStatus
): AddTaxDeclarationYearStatus => ({
  type: ADD_TAX_DECLARATION_YEAR_STATUS,
  financialYear,
  status,
});

export const setTaxDeclarationYearStatusesAction = (
  financialYear: string,
  status: PeriodStatus[]
): SetTaxDeclarationYearStatuses => ({
  type: SET_TAX_DECLARATION_YEAR_STATUSES,
  financialYear,
  status,
});

export const addTaxDeclarationYearStatus =
  (
    financialYear: string,
    status: Status
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const { user } = getState();
      const { currentCustomer } = getState().customerView;

      if (currentCustomer) {
        dispatch(
          addTaxDeclarationYearStatusAction(financialYear, {
            status,
            createdAt: new Date().toISOString(),
            createdBy: `${user.givenName} ${user.familyName}`,
            previousStatus: null,
            reason: null,
            period: financialYear,
            periodId: -1, // TODO Why are we using financial year as period?
          })
        );
      }
    } catch (e) {
      dispatch(addGlobalErrorMessage('program.status.update.error'));
    }
  };

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;
type ProgramPeriodStatuses = Awaited<
  ReturnType<Sdk['getProgramStatuses']>
>[string];

export const getTaxDeclarationYearStatus =
  (clientId: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const statuses = await sdk.getProgramStatuses({
      clientIds: [clientId],
      program: 'TAX_DECLARATION',
    });

    const statusObject: { [financialYear: string]: PeriodStatus[] } = {};

    statuses[clientId]
      ?.filter(
        (
          item
        ): item is ProgramPeriodStatuses[number] & {
          financialYear: NonNullable<
            ProgramPeriodStatuses[number]['financialYear']
          >;
          financialYearId: number;
        } =>
          typeof item.financialYear === 'string' &&
          typeof item.financialYearId === 'number'
      )
      .forEach((item) => {
        const newStatus = {
          period: item.financialYear,
          periodId: item.financialYearId,
          status: item.status,
          createdAt: item.createdAt,
          createdBy: `${item.givenName} ${item.familyName}`,
          reason: item.reason,
          previousStatus: item.previousStatus,
        };

        if (!statusObject[item.financialYear]) {
          statusObject[item.financialYear] = [newStatus];
        } else {
          statusObject[item.financialYear].push(newStatus);
        }
      });

    Object.keys(statusObject).forEach((financialYear) => {
      dispatch(
        setTaxDeclarationYearStatusesAction(
          financialYear,
          statusObject[financialYear].sort((a, b) =>
            compareAsc(parse(b.createdAt), parse(a.createdAt))
          )
        )
      );
    });
  };

export const setTaxDeclarationDocuments = (
  financialYear: string,
  documents: TaxDocumentAttachment[]
): SetTaxDeclarationDocuments => ({
  type: SET_TAX_DECLARATION_DOCUMENTS,
  financialYear,
  documents,
});

const updateTaxDocumentList =
  (
    financialYear: string,
    customerId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const updateResult = await asResultClass(
      getApiSdk(getContext()).getClientDocuments({
        clientid: customerId,
        year: formatYearVariable(financialYear),
      })
    );
    if (updateResult.err) {
      throw new Error(
        `Failed to update document list after add or delete ${updateResult.val}`
      );
    }
    dispatch(
      setTaxDeclarationDocuments(
        financialYear,
        updateResult.val.listDocuments.filter(
          (doc) => doc.category === 'taxDeclaration/other'
        )
      )
    );
  };

export const addTaxDeclarationDocument =
  (
    financialYear: string,
    document: File
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const customerId = getState().customerView.currentCustomer;
      if (customerId) {
        const sdk = getApiSdk(getContext());
        const addResult = await asResultClass(
          sdk.putClientDocument({
            clientid: customerId,
            year: formatYearVariable(financialYear),
            name: document.name,
            category: 'taxDeclaration/other',
            requestBody: document,
            program: 'TAX_DECLARATION',
            section: 'FORM',
          })
        );
        if (addResult.err) {
          throw new Error(`Failed to add new document ${addResult.val}`);
        }

        dispatch(updateTaxDocumentList(financialYear, customerId));
      }
    } catch (e) {
      console.error(e);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

export const deleteTaxDeclarationDocument =
  (
    financialYear: string,
    documentName: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const customerId = getState().customerView.currentCustomer;
      if (customerId) {
        const sdk = getApiSdk(getContext());
        const deleteResult = await asResultClass(
          sdk.deleteClientDocument({
            clientid: customerId,
            year: formatYearVariable(financialYear),
            category: 'taxDeclaration/other',
            name: documentName,
            program: 'TAX_DECLARATION',
            section: 'FORM',
          })
        );
        if (deleteResult.err) {
          throw new Error(`Failed to delete document ${deleteResult.val}`);
        }
        dispatch(updateTaxDocumentList(financialYear, customerId));
      }
    } catch (e) {
      console.error(e);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

export const setConnectionsChart = (
  financialYear: string,
  connectionsChart: ConnectionNode[]
): SetConnectionsChart => ({
  type: SET_TAX_CONNECTIONS_CHART,
  financialYear,
  connectionsChart,
});

export const setOwners = (
  financialYear: string,
  owners: ShareOwners[]
): SetOwners => ({
  type: SET_TAX_OWNERS,
  financialYear,
  owners,
});

export const deleteTaxDeclarationYear = (
  financialYear: string
): DeleteTaxDeclarationYear => ({
  type: DELETE_TAX_DECLARATION_YEAR,
  financialYear,
});

const setTaxDeclarationYearWithInfo = (
  financialYear: string,
  forms: TaxDeclarationForm[],
  documents: TaxDocumentAttachment[],
  definitions: TaxDeclarationFormDefinition[],
  connectionsChart: ConnectionNode[]
): SetTaxDeclarationYearWithInfo => ({
  type: SET_TAX_DECLARATION_YEAR_WITH_INFO,
  financialYear,
  forms,
  documents,
  definitions,
  connectionsChart,
});

export const fetchTaxYearInfoForPerson =
  (
    personId: string,
    missingYear: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());
    const [
      formsResult,
      definitions,
      companyDefinitions,
      documentsResult,
      chart,
    ] = await Promise.all([
      asResultClass(
        sdk.getTaxDeclarationDocuments({
          financialYear: missingYear,
          clientId: personId,
        })
      ),
      await getForms(missingYear, 'P'),
      await getForms(missingYear, 'C'),
      await asResultClass(
        sdk.getClientDocuments({
          clientid: personId,
          year: formatYearVariable(missingYear),
        })
      ),
      await asResultClass(
        sdk.getTaxOrganisationChart({
          clientId: personId,
          financialYear: missingYear,
        })
      ),
    ]);
    if (formsResult.err) {
      // eslint-disable-next-line no-console
      console.error(
        `Error getting client forms for ${missingYear}`,
        formsResult.val
      );
      throw new Error(`Error getting client forms for ${missingYear}`);
    }
    if (documentsResult.err) {
      // eslint-disable-next-line no-console
      console.error(
        `Error getting client documents for ${missingYear}`,
        documentsResult.val
      );
      throw new Error(`Error getting client documents for ${missingYear}`);
    }
    if (chart.err) {
      dispatch(addGlobalErrorMessage('chart.loading.error'));
    }

    const forms = mapAndGroupStoredForms(formsResult.val);
    const documents = documentsResult.val.listDocuments.filter(
      (doc) => doc.category === 'taxDeclaration/other'
    );

    const allDefinitions = [
      ...definitions,
      ...companyDefinitions.filter(
        (def) => def.name && ['NE', 'NEA'].includes(def.name)
      ),
    ].map((def) => {
      if (def.name === 'NE') {
        // now we have NE and NE_PERSON and both are of type SKV-2161
        // the redux store stores the form as {[type]: form} so
        // one of the forms will be overwritten otherwise
        return {
          ...def,
          type: 'C_SKV-2161',
        };
      }

      return def;
    });

    dispatch(
      setTaxDeclarationYearWithInfo(
        missingYear,
        forms,
        documents,
        allDefinitions,
        chart.ok ? chart.val : []
      )
    );

    // always add INK1 for private persons if one hasn't been created
    if (formsResult.val.length === 0) {
      await dispatch(addTaxDeclarationForm(missingYear, 'SKV-2001', {}));
    }
  };

export const fetchTaxDeclarationForms =
  (
    customerId: string,
    financialYear: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const formsResult = await asResultClass(
      sdk.getTaxDeclarationDocuments({
        financialYear,
        clientId: customerId,
      })
    );
    if (formsResult.err) {
      throw new Error(`Error getting client forms for ${financialYear}`);
    }

    dispatch(
      setTaxDeclarationForms(
        financialYear,
        mapAndGroupStoredForms(formsResult.val)
      )
    );
  };
