import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { DraggableItemTypes } from 'contants';
import { SpecificationRowType } from '_clients/types/types';

type RowCollectedProps = {
  isOver: boolean;
  canDrop: boolean;
  isDragging?: boolean;
  item?: SpecificationRowType;
};

type RowDragObject = {
  row: SpecificationRowType;
};

const collect = (monitor: DropTargetMonitor): RowCollectedProps => {
  return {
    isOver: !!monitor.isOver(),
    canDrop: !!monitor.canDrop(),
    item: monitor.getItem(),
  };
};

export const useRowDrop = (
  rowId: number,
  onDrop: (row: SpecificationRowType) => void
) => {
  return useDrop<RowDragObject, void, RowCollectedProps>(
    {
      accept: DraggableItemTypes.SPECIFICATION_ROW,
      canDrop: (item) => rowId !== item.row.id,
      drop: (item) => onDrop(item.row),
      collect,
    },
    [rowId, onDrop]
  );
};

export const useRowDrag = (row: SpecificationRowType, canDrag: boolean) => {
  return useDrag<RowDragObject, void, RowCollectedProps>(
    {
      type: DraggableItemTypes.SPECIFICATION_ROW,
      item: { row },
      canDrag: () => canDrag,
      collect: (monitor: DragSourceMonitor) => ({
        canDrop: false,
        isOver: false,
        isDragging: monitor.isDragging(),
      }),
    },
    [row, canDrag]
  );
};
