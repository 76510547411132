import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';

import { asResultClass, useApiSdk } from 'api-sdk';
import { AgoyPrograms } from 'types';
import { useSelector } from 'redux/reducers';
import {
  addGlobalErrorMessage,
  setCurrentCustomerAction,
  setIntegrationStatus,
} from 'redux/actions';
import { formatFinancialYear } from '@agoy/common';
import { useLicenseStatus } from '_payment/licenses/useLicense';
import { ClientsRouteParams, ClientPersonsRouteParams } from 'routes/types';

import { getFinancialYear } from '_shared/redux/checklist/utils';
import { getPerson } from '_person/redux/persons/actions';
// @ts-ignore
import { ReactComponent as ReconIcon } from './assets/fa_list-check.svg';
// @ts-ignore
import { ReactComponent as TaxesIcon } from './assets/fa_stamp.svg';
// @ts-ignore
import { ReactComponent as ARIcon } from './assets/fa_file-chart-pie.svg';
// @ts-ignore
import { ReactComponent as ReportIcon } from './assets/fa_chart-column.svg';
// @ts-ignore
import { ReactComponent as DeclarationIcon } from './assets/fa_landmark.svg';

import UpdateSIEButton from './UpdateSIEButton';
import IconMenu from './IconMenu';

const programs: AgoyPrograms[] = [
  'reconciliation',
  'tax',
  'year',
  'taxdeclaration',
  'financialreport',
];

const programsPersons: AgoyPrograms[] = ['taxdeclaration'];

const YearIcon = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: bold;
  width: 48px;
  font-size: 18px;

  &:hover {
    color: #007533;
  }
`;

/**
 * This is the data provider rendering the icon menu (see IconMenu.tsx), it takes care of the
 * legacy glue with url and redux store.
 * @returns JSX component
 */
export const IconMenuProvider = (): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { license } = useLicenseStatus();
  const {
    params: { clientId, financialYear, program },
  } = useRouteMatch<ClientsRouteParams>();
  const {
    params: { personId },
  } = useRouteMatch<ClientPersonsRouteParams>();

  const [allowedPrograms, setAllowedPrograms] = useState<Array<AgoyPrograms>>(
    []
  );
  const orgOrigin = useSelector((state) => state.organisation.origin);
  const financialYears = useSelector((state) => {
    if (clientId) {
      const years = state.customers?.[clientId]?.financialYearIds;
      return years ? Object.keys(years).sort().reverse() : [];
    }

    if (personId) {
      const years = state.persons?.[personId]?.financialYears;
      return years ? Object.values(years).sort().reverse() : [];
    }

    return [];
  });

  const personSelectedYear = useSelector((state) => {
    if (personId) {
      return state.customerView.currentFinancialYear;
    }
    return undefined;
  });

  const customer = useSelector((state) =>
    clientId ? state.customers[clientId] : undefined
  );

  const person = useSelector((state) =>
    personId ? state.persons[personId] : undefined
  );

  const sdk = useApiSdk();

  const fortnoxIntegration = customer?.integrations?.fortnox;
  const fortnoxStatus = customer?.integrations?.fortnox;

  useEffect(() => {
    if (fortnoxIntegration === undefined && clientId) {
      const get = async () => {
        try {
          const { isActive } = await sdk.getFortnoxIntegrationStatus({
            clientid: clientId,
          });

          if (isActive) {
            dispatch(
              setIntegrationStatus(clientId, 'fortnox', {
                lastModified: new Date().toISOString(),
                lastAccessed: new Date().toISOString(),
              })
            );
          } else {
            dispatch(setIntegrationStatus(clientId, 'fortnox', null));
          }
        } catch (error) {}
      };
      get();
    }
  }, [fortnoxIntegration, clientId, dispatch, sdk]);

  const currentFinancialYear = financialYear || financialYears[0];

  const iconPrograms = useMemo(() => {
    return [
      {
        id: 'reconciliation',
        icon: (
          <div style={{ width: '25px' }}>
            <ReconIcon />
          </div>
        ),
        onClick: () => {
          if (!currentFinancialYear) {
            return;
          }
          history.push(
            `/clients/${clientId}/reconciliation/${currentFinancialYear}`
          );
        },
        selected: program === 'reconciliation',
        tooltip: 'Avstämning',
      },
      {
        id: 'tax',
        icon: (
          <div style={{ width: '25px' }}>
            <TaxesIcon />
          </div>
        ),
        onClick: () => {
          if (!currentFinancialYear) {
            return;
          }
          history.push(`/clients/${clientId}/${currentFinancialYear}/tax`);
        },
        selected: program === 'tax',
        tooltip: 'Bokslut',
      },
      {
        id: 'year',
        icon: (
          <div style={{ width: '25px' }}>
            <ARIcon />
          </div>
        ),
        onClick: () => {
          if (!currentFinancialYear) {
            return;
          }
          history.push(`/clients/${clientId}/${currentFinancialYear}/year`);
        },
        selected: program === 'year',
        tooltip: 'Årsredovisning',
      },
      {
        id: 'financialreport',
        icon: (
          <div style={{ width: '25px' }}>
            <ReportIcon />
          </div>
        ),
        onClick: () => {
          if (!currentFinancialYear) {
            return;
          }
          history.push(
            `/clients/${clientId}/${currentFinancialYear}/financialreport`
          );
        },
        selected: program === 'financialreport',
        tooltip: 'Rapport',
      },
      {
        id: 'taxdeclaration',
        icon: (
          <div style={{ width: '25px' }}>
            <DeclarationIcon />
          </div>
        ),
        onClick: () => {
          if (clientId) {
            if (!currentFinancialYear) {
              return;
            }
            history.push(
              `/clients/${clientId}/${currentFinancialYear}/taxdeclaration`
            );
          } else if (!clientId && personId) {
            history.push(`/persons/${personId}/taxdeclaration`);
          }
        },
        selected: program === 'taxdeclaration',
        tooltip: 'Skatt',
      },
    ];
  }, [clientId, currentFinancialYear, history, personId, program]);

  /**
   * License check as to which programs are allowed to be shown
   */
  useEffect(() => {
    (() => {
      if (clientId) {
        if (license === 'small') {
          setAllowedPrograms(['reconciliation', 'financialreport']);
        } else if (license === 'big') {
          setAllowedPrograms(programs);
        }
      }

      if (!clientId && personId) {
        setAllowedPrograms(programsPersons);
      }
    })();
  }, [clientId, license, orgOrigin, personId]);

  const handleAddYearsForPerson = async () => {
    if (!personId) return;
    const pastYear = await asResultClass(
      sdk.addPastFinancialYear({ clientid: personId })
    );

    if (pastYear.ok) {
      // We refetch the person to update the store with the new year
      dispatch(getPerson(personId));
    }

    if (pastYear.err) {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  return (
    <IconMenu
      onSettingsClick={() =>
        history.push(
          `/${personId ? 'persons' : 'clients'}/${personId || clientId}/`
        )
      }
      programs={iconPrograms.filter((product) =>
        allowedPrograms.includes(product.id as AgoyPrograms)
      )}
      currentClient={clientId}
      currentFinancialYear={financialYear}
      currentProgram={program}
      years={financialYears.map((year) => ({
        id: year,
        icon: <YearIcon>{`-${year.substring(11, 13)}`}</YearIcon>,
        onClick: () => {
          if (personId && person) {
            dispatch(
              setCurrentCustomerAction(
                personId,
                year.substring(0, 4),
                year,
                null,
                getFinancialYear(person.rawFinancialYears, year)?.id
              )
            );
            return;
          }
          if (program) {
            history.push(`/clients/${clientId}/${year}/${program}`);
            return;
          }

          history.push(`/clients/${clientId}/reconciliation/${year}`);
        },
        selected: personId
          ? year === personSelectedYear
          : year === financialYear,
        tooltip: formatFinancialYear(year) || '',
      }))}
      addYear={personId ? handleAddYearsForPerson : undefined}
      fortnoxIntegration={
        fortnoxStatus && !fortnoxStatus.deleted && <UpdateSIEButton />
      }
    />
  );
};

export default IconMenuProvider;
