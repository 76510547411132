import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from '_shared/components/Modal/v2/Modal';
import Button from '_shared/components/Buttons/Button';
import {
  AgoyTable,
  AgoyTableRow,
  booleanValue,
  stringValue,
} from '@agoy/document';
import styled from '@emotion/styled';
import Typography from '_shared/components/Typography/Typography';
import Checkbox from '_shared/components/Controls/Checkbox';
import { useDispatch } from 'react-redux';
import { updateTaxDeclarationFormTableRows } from 'redux/actions';
import useEscapeKeyHandler from '../Common/hooks/useEscapeKeyHandler';

const List = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(1)}px;
`;

const ListHeader = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacing(4)}px;
  padding: ${({ theme }) => `${theme.spacing(0.75)}px ${theme.spacing(0)}px`};
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  align-self: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.black};
`;

const ListBody = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(0.25)}px ${theme.spacing(3)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0)}px`};
`;

const ListItem = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacing(4)}px;
  align-items: center;
`;

type SelectItem = { selected: boolean; label: string | undefined };

interface TaxSummaryTableEditModalProps {
  tableId: string;
  table: AgoyTable;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const TaxSummaryTableEditModal = ({
  tableId,
  table,
  open,
  setOpen,
}: TaxSummaryTableEditModalProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState<Record<string, SelectItem>>(
    {}
  );

  useEscapeKeyHandler(() => setOpen(false));

  useEffect(() => {
    const parentRow = table.rows?.[0];
    const selectItems =
      parentRow?.rows
        ?.filter((subRow) => subRow.type !== 'sum')
        .reduce<Record<string, SelectItem>>(
          (items: Record<string, SelectItem>, row: AgoyTableRow) => ({
            ...items,
            [`fields.${tableId}.${parentRow.id}.${row.id}`]: {
              selected: booleanValue(row.active),
              label: stringValue(row.cells?.label),
            },
          }),
          {}
        ) ?? {};
    setSelectedRows(selectItems);
  }, [table]);

  const onChange = (id: string) => {
    setSelectedRows((selection) => ({
      ...selection,
      [id]: { ...selection[id], selected: !selection[id].selected },
    }));
  };

  const submit = () => {
    dispatch(
      updateTaxDeclarationFormTableRows(
        Object.entries(selectedRows).map(([id, item]) => ({
          id: `TS.${id}`,
          active: item.selected,
        }))
      )
    );

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={formatMessage({ id: 'edit.table' })}
      footer={
        <>
          <Button
            label={formatMessage({ id: 'cancel' })}
            variant="text"
            onClick={() => setOpen(false)}
          />
          <Button
            label={formatMessage({
              id: 'tax.taxSummary.table.modal.ok',
            })}
            onClick={submit}
          />
        </>
      }
    >
      <List>
        <ListHeader>
          <Typography margin="none" variant="h3">
            {table.columns[0].label}
          </Typography>
        </ListHeader>

        <ListBody>
          {Object.entries(selectedRows).map(([id, item]) => (
            <ListItem key={id}>
              <Checkbox
                checked={item.selected}
                label={item.label}
                gap={2}
                onChange={() => onChange(id)}
              />
            </ListItem>
          ))}
        </ListBody>
      </List>
    </Modal>
  );
};

export default TaxSummaryTableEditModal;
