export const SET_UI_STATUS = 'SET_UI_STATUS';

// Annual Report
export const SET_ANNUAL_REPORT_CHANGES = 'SET_ANNUAL_REPORT_CHANGES';

// Tax declaration
export const SET_TAX_DECLARATION_VIEW_STATE = 'SET_TAX_DECLARATION_VIEW_STATE';
export const ADD_TAX_DECLARATION_YEAR = 'ADD_TAX_DECLARATION_YEAR';
export const DELETE_TAX_DECLARATION_FORM = 'DELETE_TAX_DECLARATION_FORM';
export const VIEW_TAX_DECLARATION_FORM = 'VIEW_TAX_DECLARATION_FORM';
export const SET_TAX_DECLARATION_FORM_STATUS =
  'SET_TAX_DECLARATION_FORM_STATUS';
export const SET_TAX_DECLARATION_FORM_DESCRIPTION =
  'SET_TAX_DECLARATION_FORM_DESCRIPTION';
export const SET_TAX_DECLARATION_FORMS = 'SET_TAX_DECLARATION_FORMS';
export const SET_TAX_DECLARATION_DEFS = 'SET_TAX_DECLARATION_FORM_DEFS';
export const SET_TAX_DECLARATION_CONTENT_DEF =
  'SET_TAX_DECLARATION_CONTENT_DEF';
export const SET_TAX_DECLARATION_CONTENT_DEF_BULK =
  'SET_TAX_DECLARATION_CONTENT_DEF_BULK';
export const SET_TAX_DECLARATION_FORM_STATE = 'SET_TAX_DECLARATION_FORM_STATE';
export const UPDATE_TAX_DECLARATION_FORM_FIELD =
  'UPDATE_TAX_DECLARATION_FORM_FIELD';
export const RESET_TAX_DECLARATION_FORM_FIELD =
  'RESET_TAX_DECLARATION_FORM_FIELD';
export const TOGGLE_TAX_DECLARATION_FIELD_ACTIVE =
  'TOGGLE_TAX_DECLARATION_FIELD_ACTIVE';
export const TOGGLE_TAX_DECLARATION_SECTION_ACTIVE =
  'TOGGLE_TAX_DECLARATION_SECTION_ACTIVE';
export const RESET_TAX_DECLARATION_TABLE_ROW =
  'RESET_TAX_DECLARATION_TABLE_ROW';
export const UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD =
  'UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD';
export const UPDATE_TAX_DECLARATION_FORM_DATA =
  'UPDATE_TAX_DECLARATION_FORM_DATA';
export const ADD_TAX_DECLARATION_YEAR_STATUS =
  'ADD_TAX_DECLARATION_YEAR_STATUS';
export const SET_TAX_DECLARATION_YEAR_STATUSES =
  'SET_TAX_DECLARATION_YEAR_STATUSES';
export const RESET_TAX_DECLARATION_VIEW_STATE =
  'RESET_TAX_DECLARATION_VIEW_STATE';
export const SET_TAX_DECLARATION_DOCUMENTS = 'SET_TAX_DECLARATION_DOCUMENTS';
export const SET_TAX_CONNECTIONS_CHART = 'SET_TAX_CONNECTIONS_CHART';
export const SET_TAX_OWNERS = 'SET_TAX_OWNERS';
export const DELETE_TAX_DECLARATION_YEAR = 'DELETE_TAX_DECLARATION_YEAR';
export const SET_TAX_DECLARATION_YEAR_WITH_INFO =
  'SET_TAX_DECLARATION_YEAR_WITH_INFO';
export const SET_TAX_DECLARATION_FORM_QUALITY_CHECK =
  'SET_TAX_DECLARATION_FORM_QUALITY_CHECK';

export const UPDATE_TAX_DECLARATION_TABLE_ROW_CELL =
  'UPDATE_TAX_DECLARATION_TABLE_ROW_CELL';
export const ADD_TAX_DECLARATION_TABLE_ROW = 'ADD_TAX_DECLARATION_TABLE_ROW';
export const DELETE_TAX_DECLARATION_TABLE_ROW =
  'DELETE_TAX_DECLARATION_TABLE_ROW';
export const RESET_TAX_DECLARATION_TABLE = 'RESET_TAX_DECLARATION_TABLE';
export const SET_HAS_UPLOADED_SRU_DATA = 'SET_HAS_UPLOADED_SRU_DATA';
export const TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE =
  'TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE';
export const TOGGLE_TAX_DECLARATION_TABLE_ACTIVE =
  'TOGGLE_TAX_DECLARATION_TABLE_ACTIVE';
export const UPDATE_TAX_DECLARATION_TABLE_ROWS =
  'UPDATE_TAX_DECLARATION_TABLE_ROWS';
