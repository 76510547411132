import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { SpecificationRowType } from '_clients/types/types';
import { getClasses } from '@agoy/common';
import { yellow } from '@agoy/theme/src/colors';
import { useRowDrop } from './DndHooks';

const Tr = styled.tr`
  &.over {
    td {
      border-top: 1px solid ${yellow.HIGH};
    }
  }
`;

type AddNewRowProps = {
  children: React.ReactNode;
  onDropRow: (fromIndex: number) => void;
};

const AddNewRow = ({ children, onDropRow }: AddNewRowProps) => {
  const ref = useRef<HTMLTableRowElement | null>(null);

  const handleDrop = (droppedRow: SpecificationRowType) => {
    onDropRow((droppedRow.order || 1) - 1);
  };

  const [{ isOver }, drop] = useRowDrop(-1, handleDrop);

  const handleRef = (node: HTMLTableRowElement) => {
    drop(node);
    ref.current = node;
  };

  return (
    <Tr className={getClasses({ over: !!isOver })} ref={handleRef}>
      {children}
    </Tr>
  );
};

export default AddNewRow;
