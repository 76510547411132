import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Box, Typography, Switch, Divider } from '@material-ui/core';
import { Check, Report } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';

import { Period } from '@agoy/api-sdk-core';
import { AccountingAccount } from 'types/Accounting';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { Comment } from '_shared/types';
import { InputData } from '_reconciliation/types';
import { EnableForPrint } from '_shared/components/GroupsSpecificationInfo/types';

import SpecificationInfo from '_shared/components/SpecificationInfo';
import Document from '_shared/components/Document';
import { ccyFormat } from '@agoy/common';
import SpecificationsTable from './SpecificationsTable';
import TaxesDataContext from '../../../service/TaxesDataContext';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;

  @media print {
    padding: 0;
    border: none;
    margin-bottom: ${(props) => props.theme.spacing(4)}px;
    break-inside: avoid;
  }
`;

const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.theme.palette.grey[700]};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  width: 2px;
  height: auto;
  @media print {
    display: none;
  }
`;

const StyledSwitchWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledSwitch = styled(Switch)`
  color: ${(props) => props.theme.palette.secondary.main};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  @media print {
    display: none;
  }
`;

const AccountSectionLabel = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledCheckIcon = styled(Box)`
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`;

const InternalCommentBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const DocumentWrapper = styled(Box)`
  padding: 8px;
  padding-left: 0;
  white-space: pre-wrap;
  p {
    margin-bottom: 4px;
  }
  span {
    padding-left: 0px;
  }
  @media print {
    display: flex;
    width: 1000px;
    white-space: none;
    padding: 0;
  }
`;

const StyledWrapper = styled(Box)`
  padding: 8px;
  padding-left: 0;
  white-space: pre-wrap;
  p {
    margin-bottom: 4px;
  }
  span {
    padding-left: 0px;
  }
`;

const SpecificationBox = styled(Box)`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
  @media print {
    max-width: 1000px;
  }
`;

const StyledLabel = styled(Box)`
  display: flex;
  flex-direction: 'row';
  padding-left: 8px;
`;

const AuthorTypographyBlock = styled(Typography)`
  font-weight: 500;
`;

const CommonTypographyBlock = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const SpecificationsTableContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  @media print {
    max-width: 1000px;
  }
`;

const StyledHistoryInfo = styled(Typography)`
  margin: 0;
`;

export interface BalanceHistoryType {
  account: number;
  saldo: number | null;
  createdAt: string;
  authorName: string;
}

type AccountSpecificationsItemProps = {
  inputData: InputData;
  accountInformation: AccountingAccount;
  period: Period;
  documents: PeriodDocument[];
  balanceHistory?: BalanceHistoryType;
  checkedHistory?: CheckedHistoryItemType;
  enableForPrint?: EnableForPrint;
  part: string | undefined;
  allInternalComments: Comment[];
  isPrint?: boolean;
  onSwitch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export interface CheckedHistoryItemType {
  checked: boolean;
  date: string;
  authorName: string;
  account: string;
}

const AccountSpecificationsItem = ({
  inputData,
  accountInformation,
  period,
  documents,
  balanceHistory,
  checkedHistory,
  enableForPrint = {
    comment: {},
    documents: {},
  },
  part,
  allInternalComments,
  isPrint = false,
  onSwitch = () => {},
}: AccountSpecificationsItemProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { financialYearObj: financialYear } = useContext(TaxesDataContext);

  const { legacySpecifications, comment, printComment } = inputData;

  const isBalancesEqual = useMemo(() => {
    if (balanceHistory && accountInformation.periods[period.id]) {
      return (
        ccyFormat(
          balanceHistory?.saldo != null ? balanceHistory?.saldo : undefined
        ) === ccyFormat(accountInformation.periods[period.id]?.out)
      );
    }
    return true;
  }, [accountInformation.periods, balanceHistory, period.id]);

  const internalComments = allInternalComments.filter(
    (c) =>
      c.periodId === period.id &&
      c.key === `account.${accountInformation.number}.internal`
  );

  const isCommentsEnabled =
    enableForPrint.comment[`account${accountInformation.number}`] ?? true;

  const isDocumentsEnabled =
    enableForPrint.documents[`account${accountInformation.number}`] ?? true;

  const showExternalComment = printComment && (!isPrint || isCommentsEnabled);

  const showDocuments = !isPrint || isDocumentsEnabled;

  return (
    <Container>
      {accountInformation && financialYear && (
        <SpecificationBox>
          <SpecificationInfo
            period={period}
            financialYear={financialYear}
            inputData={inputData}
            accountName={accountInformation.name}
            accountNumber={accountInformation.number}
            yearIB={accountInformation.balances[financialYear.id]?.in ?? 0}
          />
        </SpecificationBox>
      )}

      {legacySpecifications && legacySpecifications.length > 0 && (
        <SpecificationsTableContainer>
          <SpecificationsTable specifications={legacySpecifications} />
        </SpecificationsTableContainer>
      )}

      {balanceHistory && (
        <StyledWrapper>
          <StyledLabel>
            <StyledCheckIcon>
              {isBalancesEqual ? (
                <Check color="primary" />
              ) : (
                <Report color="error" />
              )}
            </StyledCheckIcon>
            <StyledHistoryInfo>
              Faktiskt saldo fylldes i av {balanceHistory.authorName}{' '}
              {format(parseISO(balanceHistory.createdAt), 'yyyy-MM-dd')} och
              uppgick till {balanceHistory.saldo} SEK
            </StyledHistoryInfo>
          </StyledLabel>
        </StyledWrapper>
      )}

      {checkedHistory && (
        <StyledWrapper>
          <StyledLabel>
            <StyledCheckIcon>
              <Check color="primary" />
            </StyledCheckIcon>
            <StyledHistoryInfo>
              Faktiskt saldo kvalitetsgranskades av {checkedHistory.authorName}{' '}
              {format(parseISO(checkedHistory.date), 'yyyy-MM-dd')}
            </StyledHistoryInfo>
          </StyledLabel>
        </StyledWrapper>
      )}

      {part === 'internspecifications' && internalComments.length > 0 && (
        <StyledWrapper paddingTop={2}>
          <div>
            <AccountSectionLabel>
              {formatMessage({
                id: 'internalComment',
              })}
            </AccountSectionLabel>
            <Box>
              {internalComments.map((internalComment) => (
                <InternalCommentBox key={internalComment.id}>
                  <StyledDivider orientation="vertical" flexItem />
                  <Box>
                    <CommonTypographyBlock>
                      {internalComment.comment}
                    </CommonTypographyBlock>
                    <AuthorTypographyBlock>
                      {internalComment.userDisplayName}{' '}
                      {format(
                        parseISO(internalComment.createdAt),
                        'yyyy-MM-dd'
                      )}
                    </AuthorTypographyBlock>
                  </Box>
                </InternalCommentBox>
              ))}
            </Box>
          </div>
        </StyledWrapper>
      )}

      {comment && showExternalComment && (
        <StyledWrapper paddingTop={2}>
          <div>
            <AccountSectionLabel>
              {formatMessage({
                id:
                  part === 'internspecifications'
                    ? 'externalComment'
                    : 'comment',
              })}
            </AccountSectionLabel>
            <StyledSwitchWrapper>
              {part !== 'internspecifications' && (
                <StyledSwitch
                  checked={isCommentsEnabled}
                  size="small"
                  name={`comment.account${accountInformation.number}`}
                  onChange={onSwitch}
                />
              )}
              <Typography>{comment}</Typography>
            </StyledSwitchWrapper>
          </div>
        </StyledWrapper>
      )}

      {documents.length > 0 && showDocuments && (
        <>
          <DocumentWrapper paddingTop={2}>
            <div>
              <AccountSectionLabel>
                {formatMessage({ id: 'hidden.event.view' })}
              </AccountSectionLabel>
              {part !== 'internspecifications' && (
                <StyledSwitch
                  checked={isDocumentsEnabled}
                  size="small"
                  name={`documents.account${accountInformation.number}`}
                  onChange={onSwitch}
                />
              )}
            </div>
          </DocumentWrapper>
          {documents.map((document, index) => (
            <DocumentWrapper key={document.id} paddingTop={2}>
              <Document
                name={document.name}
                url={document.url}
                isPrint={isPrint}
                disableBreakAfter={index === documents.length - 1}
              />
            </DocumentWrapper>
          ))}
        </>
      )}
    </Container>
  );
};

export default AccountSpecificationsItem;
