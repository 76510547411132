import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Search } from '@material-ui/icons';

import Button from '_shared/components/Buttons/Button';
import { Input, InputContainerStyle } from '_shared/components/Inputs/v2/Input';
import {
  SpecificationColumnType,
  SpecificationRowType,
  SpecificationColumnContentType,
} from '_clients/types/types';
import Add from '@material-ui/icons/Add';

import Expandable from './Expandable';
import ColumnItem from './ColumnItem';
import AddColumnDialog from './AddColumnDialog';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  padding: ${({ theme }) => theme.spacing(3)}px;
  gap: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: ${({ theme }) => theme.shadows[4]};
  width: 100%;
`;

const InputWrapper = styled.div`
  padding: 16px 8px 0 8px;
`;

const InputContainer = styled(InputContainerStyle)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.border.dark};
  padding-right: 32px;

  svg {
    position: absolute;
    right: 8px;
  }
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const AddIcon = styled(Add)`
  width: 20px;
  height: 20px;
`;

const mockedColumns: SpecificationColumnType[] = [
  {
    id: 1,
    contentType: 'TEXT',
    name: 'Description',
  },
  {
    id: 2,
    contentType: 'AMOUNT',
    name: 'Total Cost',
    order: 1,
  },
  {
    id: 3,
    contentType: 'DATE',
    name: 'Start Date',
  },
  {
    id: 4,
    contentType: 'PERCENTAGE',
    name: 'Completion Rate',
    order: 2,
  },
  {
    id: 5,
    contentType: 'TEXT',
    name: 'Project Name',
  },
  {
    id: 6,
    contentType: 'DATE',
    name: 'End Date',
    order: 3,
  },
  {
    id: 7,
    contentType: 'AMOUNT',
    name: 'Discount',
  },
  {
    id: 8,
    contentType: 'TEXT',
    name: 'Notes',
    order: 4,
  },
  {
    id: 9,
    contentType: 'PERCENTAGE',
    name: 'Interest Rate',
  },
  {
    id: 10,
    contentType: 'TEXT',
    name: 'Category',
    order: 5,
  },
];

interface ColumnSelectorProps {
  selectedColumns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  onToggleColumn: (
    column: SpecificationColumnType,
    value: boolean
  ) => Promise<void>;
  onChangeColumn: (
    column: SpecificationColumnType,
    updatedField: Partial<SpecificationColumnType>
  ) => Promise<void>;
  onDeleteColumn: (id: number) => Promise<void>;
  onClose: () => void;
  onAddUserColumn: (
    name: string,
    contentType: SpecificationColumnContentType
  ) => Promise<void>;
}

const ColumnSelector = ({
  selectedColumns,
  rows,
  onToggleColumn,
  onChangeColumn,
  onDeleteColumn,
  onClose,
  onAddUserColumn,
}: ColumnSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [search, setSearch] = useState('');
  const [isAddColumnDialogOpen, setIsAddColumnDialogOpen] = useState(false);

  const selectedColumnsId = useMemo(
    () => selectedColumns.map((col) => col.id),
    [selectedColumns]
  );

  const filteredColumns = useMemo(
    () =>
      mockedColumns.filter((col) =>
        col.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ),
    [search]
  );

  const columnsWithData = useMemo(() => {
    const columns: number[] = [];

    rows.forEach((row) =>
      row.cells.forEach((cell) => {
        if (cell.value && !columns.includes(cell.columnId)) {
          columns.push(cell.columnId);
        }
      })
    );

    return columns;
  }, [rows]);

  const handleChangeName = async (
    column: SpecificationColumnType,
    name: string
  ) => {
    await onChangeColumn(column, { name });
  };

  const handleAddColumnDialog = () => {
    setIsAddColumnDialogOpen(true);
  };

  return (
    <>
      <AddColumnDialog
        open={isAddColumnDialogOpen}
        onClose={() => setIsAddColumnDialogOpen(false)}
        onSubmit={async (name, contentType) => {
          await onAddUserColumn(name, contentType);
          setIsAddColumnDialogOpen(false);
        }}
      />

      <Container>
        <InputWrapper>
          <InputContainer>
            <Input
              value={search}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Search />
          </InputContainer>
        </InputWrapper>

        {filteredColumns.length > 0 && (
          <Expandable
            title={formatMessage({ id: 'hidden.specification.manualColumns' })}
            headerEndContent={
              <HeaderButton onClick={handleAddColumnDialog}>
                <AddIcon />
              </HeaderButton>
            }
            defaultExpanded
          >
            {filteredColumns.map((column) => (
              <ColumnItem
                key={column.id}
                column={column}
                withData={columnsWithData.includes(column.id)}
                checked={selectedColumnsId.includes(column.id)}
                onChangeName={handleChangeName}
                onDelete={onDeleteColumn}
                onCheck={onToggleColumn}
              />
            ))}
          </Expandable>
        )}
      </Container>

      <ActionButtons>
        <Button
          label={formatMessage({ id: 'hidden.specification.close' })}
          size="medium"
          onClick={onClose}
        />
      </ActionButtons>
    </>
  );
};

export default ColumnSelector;
