import React, { useEffect, useRef, useState } from 'react';
import { useDragDropManager } from 'react-dnd';
import styled from '@emotion/styled';

import { SpecificationRowType } from '_clients/types/types';
import { getClasses } from '@agoy/common';
import { yellow } from '@agoy/theme/src/colors';
import { useRowDrop, useRowDrag } from './DndHooks';

const Tr = styled.tr`
  cursor: grab;
  position: relative;

  &:not(.dragging) {
    :hover {
      td {
        background-color: ${({ theme }) => theme.palette.grey[100]};
      }
    }
  }

  &.currentRowDragging {
    td {
      background-color: ${({ theme }) => theme.palette.grey[100]};
      opacity: 0.1;
    }
  }

  &.over {
    td {
      border-top: 1px solid ${yellow.HIGH};
    }
  }
`;

type DraggableRowProps = {
  children: React.ReactNode;
  row: SpecificationRowType;
  disableDragging?: boolean;
  onMoveRow: (fromIndex: number, toIndex: number) => void;
  onHover: (offsetTop?: number) => void;
};

const DraggableRow = ({
  children,
  row,
  disableDragging = false,
  onHover,
  onMoveRow,
}: DraggableRowProps) => {
  const ref = useRef<HTMLTableRowElement | null>(null);

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  const [isDragging, setIsDragging] = useState(false);

  const handleDrop = (droppedRow: SpecificationRowType) => {
    onMoveRow((droppedRow.order || 1) - 1, (row.order || 1) - 1);
  };

  const [{ isOver }, drop] = useRowDrop(row.id, handleDrop);
  const [{ isDragging: isCurrentRowDragging }, drag] = useRowDrag(
    row,
    !disableDragging
  );

  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  const handleMouseEnter = () => {
    const offsetTop = ref.current?.offsetTop || 0;
    const scrollTop = ref.current?.offsetParent?.scrollTop || 0;

    onHover(offsetTop - scrollTop);
  };

  const handleMouseLeave = () => {
    onHover();
  };

  const handleRef = (node: HTMLTableRowElement) => {
    drag(drop(node));
    ref.current = node;
  };

  return (
    <Tr
      className={getClasses({
        currentRowDragging: !!isCurrentRowDragging,
        dragging: !!isDragging,
        over: !!isOver,
      })}
      ref={handleRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </Tr>
  );
};

export default DraggableRow;
