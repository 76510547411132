import { TaxDeclarationViewActions } from 'redux/actions';
import { isError, operations } from '@agoy/document';
import {
  TaxDeclarationFormData,
  TaxDeclarationFormStructure,
} from '@agoy/tax-declaration-document';
import {
  ADD_TAX_DECLARATION_TABLE_ROW,
  DELETE_TAX_DECLARATION_TABLE_ROW,
  RESET_TAX_DECLARATION_FORM_FIELD,
  TOGGLE_TAX_DECLARATION_FIELD_ACTIVE,
  RESET_TAX_DECLARATION_TABLE_ROW,
  TOGGLE_TAX_DECLARATION_TABLE_ACTIVE,
  TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE,
  UPDATE_TAX_DECLARATION_TABLE_ROWS,
  TOGGLE_TAX_DECLARATION_SECTION_ACTIVE,
} from 'redux/actionsTypes';
import TaxDeclarationViewState from '../types';

const validateField = (
  formDefintion: TaxDeclarationFormStructure,
  partId: string,
  id: string
) => {
  const part = formDefintion.children[partId];
  if (part && 'children' in part && part.partType === 'form') {
    return part.children.fields.children[id]?.type === 'field';
  }
  return false;
};

const actionOperations = {
  [ADD_TAX_DECLARATION_TABLE_ROW]: (structure, state, { partId, id, newId }) =>
    operations.addTableRow(structure, state, `${partId}.${id}`, newId),
  [TOGGLE_TAX_DECLARATION_TABLE_ACTIVE]: (structure, state, { partId }) =>
    operations.toggleTableActive(structure, state, partId),
  [TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE]: (structure, state, { partId }) =>
    operations.toggleTableRowActive(structure, state, partId),
  [DELETE_TAX_DECLARATION_TABLE_ROW]: (structure, state, { partId, id }) =>
    operations.deleteTableRow(structure, state, `${partId}.${id}`),
  [RESET_TAX_DECLARATION_TABLE_ROW]: (structure, state, { partId, id }) =>
    operations.resetTableRow(structure, state, `${partId}.fields.${id}`),
  [UPDATE_TAX_DECLARATION_TABLE_ROWS]: (structure, state, { rows }) =>
    operations.updateTableRows(structure, state, rows),
  [TOGGLE_TAX_DECLARATION_FIELD_ACTIVE]: (structure, state, { partId }) =>
    operations.toggleFieldActive(structure, state, partId),
  [TOGGLE_TAX_DECLARATION_SECTION_ACTIVE]: (structure, state, { partId }) =>
    operations.toggleSectionActive(structure, state, partId),
  [RESET_TAX_DECLARATION_FORM_FIELD]: (
    structure,
    state,
    { partId, id, fieldConfig }
  ) => {
    // validate that field is part of definition of form and original field config is provided
    if (!validateField(structure, partId, id) || !fieldConfig) {
      return state;
    }

    const part = state.document[partId];
    if (typeof part !== 'object' || Array.isArray(part)) {
      return state;
    }

    return operations.resetField(structure, state, `${partId}.fields.${id}`);
  },
};

const updateTaxDeclarationForm = <T extends TaxDeclarationViewActions>(
  state: TaxDeclarationViewState,
  action: T
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }

  const result = actionOperations[action.type](
    formData.definition,
    {
      document: formData.document,
      changes: formData.changes,
    },
    action
  );

  if (!result || isError(result)) {
    console.warn(action, result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

export default updateTaxDeclarationForm;
