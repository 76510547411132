import React, { useState, useRef, memo, useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { pdfjs, Document, Page } from 'react-pdf';
import {
  isImageDocument,
  isPdfDocument,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';
import getFileExtension from 'utils/fileExtension';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const Image = styled.img`
  break-inside: avoid;
  display: block;
  max-width: 100%;
  @media print {
    margin-top: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ImageHeading = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  @media print {
    display: none;
  }
`;

const PdfPreview = styled(Document)`
  width: 100%;
  @media print {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const PdfPage = styled(Page)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  .react-pdf__Page__textContent {
    overflow: hidden;
  }
  @media print {
    margin-top: 0;
    page-break-inside: avoid;
  }
`;

const PageBreak = styled.a<{ disableBreakAfter: boolean }>`
  page-break-before: always;
  ${({ disableBreakAfter }) =>
    !disableBreakAfter && 'page-break-after: always;'}
`;

type DocumentComponentProps = {
  name: string;
  url: string;
  pdfPageWidth?: number;
  pdfPageHeight?: number;
  className?: string;
  hideTitle?: boolean;
  isPrint?: boolean;
  disableBreakAfter?: boolean;
  onLoad?: () => void;
};

const DocumentComponent = ({
  name,
  url,
  pdfPageWidth = 784,
  pdfPageHeight,
  className,
  hideTitle = false,
  isPrint = false,
  disableBreakAfter = false,
  onLoad,
}: DocumentComponentProps): JSX.Element | null => {
  const pagesToLoad = useRef(0);

  const [documentsPages, setDocumentsPages] = useState(0);
  const [isPdfReady, setIsPdfReady] = useState(false);

  const handlePageRender = () => {
    pagesToLoad.current -= 1;
    if (pagesToLoad.current === 0 && onLoad) {
      onLoad();
    }
  };

  const handlePDFLoading = async ({ numPages }) => {
    pagesToLoad.current = numPages;
    setDocumentsPages(numPages);
    setIsPdfReady(true);
  };

  useEffect(() => {
    pagesToLoad.current = 0;
    setIsPdfReady(false);
    setDocumentsPages(0);
  }, [name, url]);

  const extension = getFileExtension(name);

  if (isPrint) {
    // When we print PDF we don't render documents on the FE,
    // we add markers to the pdf to know where to insert attachments
    return (
      <PageBreak
        href="https://agoy.se/page-break-marker"
        disableBreakAfter={disableBreakAfter}
      >
        Page break
      </PageBreak>
    );
  }

  if (isImageDocument(extension)) {
    return (
      <>
        {!hideTitle && <ImageHeading> {name}</ImageHeading>}
        <Image src={url} alt={name} onLoad={onLoad} onError={onLoad} />
      </>
    );
  }

  if (isPdfDocument(extension)) {
    return (
      <>
        {!hideTitle && <ImageHeading> {name}</ImageHeading>}
        <PdfPreview
          file={url}
          onLoadSuccess={(pdfInfo) => handlePDFLoading(pdfInfo)}
          onLoadError={onLoad}
          className={className}
        >
          {isPdfReady &&
            Array.from(new Array(documentsPages), (el, index) => (
              <PdfPage
                width={pdfPageWidth}
                height={pdfPageHeight}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                onRenderSuccess={handlePageRender}
                onRenderError={handlePageRender}
                renderAnnotationLayer={false}
                renderForms={false}
                renderTextLayer={false}
              />
            ))}
        </PdfPreview>
      </>
    );
  }

  return null;
};

export default memo(DocumentComponent);
