import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';
import Close from '@material-ui/icons/Close';
import Alert from '_shared/components/AlertBanners/Alert';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`;

const CloseIcon = styled(Close)`
  font-size: 24px;
`;

type ResyncSpecificationDialogProps = {
  open: boolean;
  onClose: () => void;
  onResync: () => Promise<void>;
};

const ResyncSpecificationDialog = ({
  open,
  onClose,
  onResync,
}: ResyncSpecificationDialogProps) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  const handleDialogSubmit = async () => {
    setLoading(true);
    await onResync();
    setLoading(false);
    onClose();
  };

  return (
    <StyledDialog open={open}>
      <Row>
        <Typography variant="h2" margin="none">
          {formatMessage({ id: 'hidden.specification.resyncDialog.title' })}
        </Typography>
        <IconButton disabled={loading} size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Row>

      <Alert type="warning">
        <Typography margin="none">
          {formatMessage({
            id: 'hidden.specification.resyncDialog.alertMessage',
          })}
        </Typography>
      </Alert>

      <ButtonContainer>
        <Button
          label={formatMessage({
            id: 'hidden.specification.resyncDialog.cancel',
          })}
          variant="text"
          disabled={loading}
          onClick={onClose}
        />
        <Button
          label={formatMessage({
            id: 'hidden.specification.resyncDialog.submit',
          })}
          color="primary"
          loading={loading}
          onClick={handleDialogSubmit}
        />
      </ButtonContainer>
    </StyledDialog>
  );
};

export default ResyncSpecificationDialog;
