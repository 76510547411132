import { ApiReturnType } from 'api-sdk';
import { ClientCompany } from '@agoy/api-sdk-core';
import {
  CompanyConnection,
  ShareOwners,
  StockTotal,
} from '_client-connections/types';
import { FortnoxIntegrationStatus } from '_integration/types';

export enum ClientMemberRole {
  AccountingConsultant = 'AccountingConsultant',
}

export type CompanyType =
  | 'shares'
  | 'limited'
  | 'limited_partnership'
  | 'individual'
  | 'economic_association'
  | 'non_profit_association'
  | 'foundation'
  | 'other';

export enum ClosingMonthType {
  January,
  February,
  Mars,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export type ClosingPeriod = 'year' | 'month' | 'quarter';

export type LampValue =
  | 'done'
  | 'wrong'
  | 'started'
  | 'unvisited'
  | 'doesNotExist';

export interface Lamps {
  a1630: LampValue;
  rest: LampValue;
  a15xx: LampValue;
  a24xx: LampValue;
  a19xx: LampValue;
  moms: LampValue;
}

export type IntegrationType = 'fortnox';

export type ProgramStatus = {
  clientId: string;
  createdAt: string;
  period: string;
  financialYear: string;
  program: string;
  status: string;
};

export type ClientChecklist = {
  financialYearId: number;
  templateId: number;
  program: string;
  includeInPrint: boolean;
};

export type ClientTag = {
  id: string;
  name: string;
  organisationId: string;
};

export interface ClientCompanyType {
  sortKey?: string;
  id: string;
  name: string;
  type: CompanyType;
  active: boolean;
  closingPeriod: ClosingPeriod | null;
  closingMonth: ClosingMonthType | null;
  taxOutgoingBalanceDate?: string | Date;
  taxOutgoingBalanceAmount?: number;
  tags?: ClientCustomersTagsType;
  assignees?: ClientCustomersAssigneeType;
  period: string;
  managerId: string | null;
  thirdPartyId: string | null;
  vatPeriod: string | null;
  orgNumber: string | null;
  lamps: {
    [index: string]: Lamps;
  };
  /**
   * Array of financial years as strings in `yyyyMMdd-yyyyMmdd` format.
   * Sorted in ascending order.
   */
  financialYears: string[];
  /**
   * Array of financial years with periods and ids, intended to replace
   * the `financialYears` later.
   */
  rawFinancialYears: ClientCompany['financialYears'];
  latestFinancialYear?: string;
  logo: string | null;
  logoOriginal: string | null;
  integrations?: Record<IntegrationType, FortnoxIntegrationStatus>;
  contact: string | null;
  number: string | null;
  email: string | null;
  address: string | null;
  financialYearIds: Record<string, number> | null;
  startDate: string | null;
  programStatus: ProgramStatus[];
  stockTotals?: StockTotal[];
  connections?: CompanyConnection[];
  shareOwners?: ShareOwners[];
  checklists?: ClientChecklist[];
  isFortnoxCustomer: boolean | null;
}

export type ClientFinancialYears = ClientCompanyType['rawFinancialYears'];

export type ClientPersonType = ApiReturnType<'getClientPersons'>[number];

export type ClientCustomerTagType = {
  id: string;
  name: string;
};

export type ClientCustomersTagsType = ClientCustomerTagType[];

export type ClientCustomerAssigneeType = {
  id: string;
  name: string;
  email?: string;
};

export type ClientCustomersAssigneeType = ClientCustomerAssigneeType[] | [];

export type LegacySpecification = {
  account: string;
  amount: string;
  description: string;
  id: number;
  reference: string;
};

export type SpecificationType = {
  id?: number | null;
  sortColumnId?: number | null;
  sortOrder?: 'ASC' | 'DESC' | null;
  actualBalanceColumnId?: number | null;
};

export type SpecificationColumnType = {
  id: number;
  name: string;
  contentType: string;
  order?: number;
};

export type SpecificationCellType = {
  columnId: number;
  rowId: number;
  value: string;
};

export type SpecificationRowType = {
  id: number;
  order?: number;
  cells: SpecificationCellType[];
};

export type SpecificationColumnContentType =
  | 'TEXT'
  | 'AMOUNT'
  | 'DATE'
  | 'PERCENTAGE';

export const SpecificationColumnContentTypes = [
  { value: 'AMOUNT', label: 'Belopp' },
  { value: 'DATE', label: 'Datum' },
  { value: 'TEXT', label: 'Fritextfält' },
  { value: 'PERCENTAGE', label: 'Procent' },
];
