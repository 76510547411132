import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import DocumentView from '_shared/components/Document';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import {
  AccountPlanList,
  ReferencesTypes,
  VoucherStatusType,
  VoucherTransaction,
} from 'types/Voucher';
import TransactionTable from '../TransactionTable/TransactionTable';
import { Document } from '../Documents';

const CreateSection = styled.section`
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  width: 100%;
`;

const ContentDescription = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    font-size: 14px;
    width: 100%;
  }
  .MuiOutlinedInput-root {
    height: 32px;
  }
  padding-left: 5px;
`;

const Content = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput-root {
    height: 32px;
    font-size: 14px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
    width: 200px;
  }
`;

const PrintContent = styled(Content)`
  margin-right: 20px;
`;

const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.accountingView.headers.main};
`;

const TitleWithInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Documents = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacing(1.5)}px;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(2)}px;
`;

const DateAndSeriesContainer = styled.div`
  display: flex;
  gap: 100px;
`;

interface PreviewVoucherContentProps {
  selectedDate: string;
  voucherDescription: string;
  status?: VoucherStatusType;
  seriesNumber?: string;
  transactions: VoucherTransaction[];
  accounts?: AccountPlanList[];
  documents?: Document[];
  title?: string;
  reference?: ReferencesTypes;
}

const PreviewVoucherContent = ({
  selectedDate,
  voucherDescription,
  transactions,
  accounts = [],
  documents = [],
  title,
  status,
  reference,
  seriesNumber,
}: PreviewVoucherContentProps) => {
  const { formatMessage } = useIntl();

  const getText = (textId: string) => {
    return formatMessage({ id: textId });
  };

  const formattedDocuments = documents.map((item, i) => {
    return {
      id: i,
      name: item.name,
      url: item.url,
    };
  }) as PeriodDocument[];

  return (
    <div>
      <TableHeaderWrapper>
        <TitleWithInfoWrapper>
          <TableTitle>
            {title ??
              `${getText(
                'reconciliation.speedDial.voucher.title'
              )} ${seriesNumber}*`}
          </TableTitle>
          {status === VoucherStatusType.FINAL &&
            reference !== ReferencesTypes.GLIDER && (
              <TableTitle>{seriesNumber}</TableTitle>
            )}
        </TitleWithInfoWrapper>
      </TableHeaderWrapper>

      <CreateSection>
        <PrintContent>
          <DateAndSeriesContainer>
            <ContentDescription>
              <Typography variant="caption">
                {getText('reconciliation.speedDial.voucher.date')}
              </Typography>
              <Typography>{selectedDate}</Typography>
            </ContentDescription>
            <ContentDescription>
              <Typography variant="caption">
                {formatMessage({ id: 'STATUS' })}
              </Typography>
              <Typography>
                {formatMessage({
                  id: `reconciliation.speedDial.voucher.status.${status}`,
                })}
              </Typography>
            </ContentDescription>
          </DateAndSeriesContainer>

          <ContentDescription>
            <Typography variant="caption">
              {getText('reconciliation.speedDial.voucher.description')}
            </Typography>
            <Typography>{voucherDescription}</Typography>
          </ContentDescription>
        </PrintContent>
      </CreateSection>

      <TransactionTable
        records={transactions}
        accounts={accounts}
        editMode={false}
      />

      <Documents>
        {formattedDocuments.map((item, index) => (
          <DocumentView
            key={item.id}
            name={item.name}
            url={item.url}
            disableBreakAfter={index === formattedDocuments.length - 1}
            isPrint
            onLoad={() => {}}
          />
        ))}
      </Documents>
    </div>
  );
};

export default PreviewVoucherContent;
