import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';
import {
  SimplifiedSelect,
  SimplifiedTextfield,
} from '_shared/components/Inputs';
import {
  SpecificationColumnContentType,
  SpecificationColumnContentTypes,
} from '_clients/types/types';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`;

const CloseIcon = styled(Close)`
  font-size: 24px;
`;

type AddColumnDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    name: string,
    contentType: SpecificationColumnContentType
  ) => Promise<void>;
};

const AddColumnDialog = ({ open, onClose, onSubmit }: AddColumnDialogProps) => {
  const { formatMessage } = useIntl();

  const [nameValue, setNameValue] = useState('');
  const [typeValue, setTypeValue] = useState<
    SpecificationColumnContentType | ''
  >('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setNameValue('');
      setTypeValue('');
    }
  }, [open]);

  const handleDialogSubmit = async () => {
    if (nameValue && typeValue) {
      setLoading(true);
      await onSubmit(nameValue, typeValue);
      setLoading(false);
    }
  };

  const onSetValue = (e) => setTypeValue(e.target.value);

  return (
    <StyledDialog open={open}>
      <Row>
        <Typography variant="h2" margin="none">
          {formatMessage({ id: 'hidden.specification.addColumn.title' })}
        </Typography>
        <IconButton disabled={loading} size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Row>
      <Typography margin="none">
        {formatMessage({ id: 'hidden.specification.addColumn.subTitle' })}
      </Typography>
      <Row>
        <SimplifiedTextfield
          editing
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
          disabled={false}
          width={240}
          placeholderInput={formatMessage({
            id: 'hidden.specification.addColumn.name.label',
          })}
        />
        <SimplifiedSelect
          editing
          displayEmpty
          listItems={SpecificationColumnContentTypes}
          value={typeValue}
          onChange={onSetValue}
          width={240}
          placeholderInput={formatMessage({
            id: 'hidden.specification.addColumn.type.label',
          })}
          menuPlacement="bottom"
        />
      </Row>

      <ButtonContainer>
        <Button
          label={formatMessage({ id: 'hidden.specification.addColumn.cancel' })}
          variant="text"
          disabled={loading}
          onClick={onClose}
        />
        <Button
          label={formatMessage({ id: 'hidden.specification.addColumn.submit' })}
          color="primary"
          loading={loading}
          disabled={!nameValue || !typeValue}
          onClick={handleDialogSubmit}
        />
      </ButtonContainer>
    </StyledDialog>
  );
};

export default AddColumnDialog;
