import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FormControl, FormHelperText } from '@material-ui/core';
import styled from '@emotion/styled';

import SimplifiedTextfield from '_shared/components/Inputs/SimplifiedTextfield';
import SimplifiedSelect from '_shared/components/Inputs/SimplifiedSelect';
import {
  ChecklistTemplate,
  ChecklistGroup,
  Program,
  Type,
} from '_shared/redux/checklist/types';
import { ALL_PROGRAMS, TYPES } from '_shared/redux/checklist/constants';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import Button from '_shared/components/Buttons/Button';
import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage, getClients } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { getPersons } from '_person/redux/persons/actions';
import Typography from '_shared/components/Typography/Typography';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import Alert from '_shared/components/AlertBanners/Alert';
import ConfirmationDialog from '_shared/components/Dialogs/ConfirmationDialog';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import ChecklistTree from '../ChecklistTree';
import ChecklistFormClientSelect, {
  ClientSelectValue,
} from '../ChecklistClientSelect/ChecklistClientSelect';

const defaultChecklists = import.meta.glob('../../DefaultChecklists/*.json');

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const AlertWrapper = styled.div`
  max-width: 966px;
`;

const InputsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const InputsColumn = styled.div`
  display: flex-grid;
  grid-template-columns: 1fr;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const InputsColumnItem = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const ButtonContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  column-gap: ${({ theme }) => theme.spacing(2)}px;
`;

const Select = styled(SimplifiedSelect)`
  padding: 0;
`;

const TextField = styled(SimplifiedTextfield)`
  padding: 0;
`;

interface ChecklistFormProps {
  onChangeChecklist: (checklist: ChecklistTemplate) => void;
  onCancel: () => void;
  checklist?: ChecklistTemplate;
  loading?: boolean;
  readonly?: boolean;
}

const emptyChecklist: ChecklistTemplate = {
  id: null,
  name: '',
  description: '',
  state: 'active',
  program: 'reconciliation_fin_statement_company',
  type: 'empty',
  groups: [],
  clientIds: [],
};

const ChecklistForm = ({
  onChangeChecklist,
  onCancel,
  checklist,
  loading,
  readonly = false,
}: ChecklistFormProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const { organisationId } = useSelector((state) => state.user);

  const allCompanyClients = useSelector((state) => state.customers);

  const allPersonClients = useSelector((state) => state.persons);

  const [currentChecklist, setCurrentChecklist] = useState<ChecklistTemplate>(
    checklist || emptyChecklist
  );

  const [companyClientList, setCompanyClientList] = useState<
    ClientSelectValue[]
  >([]);

  const [privateClientList, setPrivateClientList] = useState<
    ClientSelectValue[]
  >([]);

  const [formErrors, setFormErrors] = useState<{
    name: string;
    program: string;
    type: string;
  }>({
    name: '',
    program: '',
    type: '',
  });

  const [showEditWarningDialog, setShowEditWarningDialog] = useState(false);

  const [isEditChecklistTree, setIsEditChecklistTree] = useState(false);

  const [showChecklistTreeWarning, setShowChecklistTreeWarning] =
    useState(true);

  useEffect(() => {
    (async () => {
      if (organisationId) {
        try {
          dispatch(getClients());
          dispatch(getPersons());
        } catch (error) {
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    })();
  }, [organisationId, dispatch]);

  useEffect(() => {
    if (allCompanyClients) {
      const companyClients: ClientSelectValue[] = [];
      Object.entries(allCompanyClients).forEach(([key, value]) => {
        if (value) {
          companyClients.push({ value: key, label: value.name });
        }
      });
      setCompanyClientList(companyClients);
    }
  }, [allCompanyClients]);

  useEffect(() => {
    if (allPersonClients) {
      const privateClients: ClientSelectValue[] = [];
      Object.entries(allPersonClients).forEach(([key, value]) => {
        if (value) {
          privateClients.push({
            value: key,
            label: `${value.firstName} ${value.lastName}`,
          });
        }
      });
      setPrivateClientList(privateClients);
    }
  }, [allPersonClients]);

  useEffect(() => {
    if (checklist && currentChecklist.id !== checklist.id) {
      setCurrentChecklist(checklist);
    }
  }, [checklist, currentChecklist.id]);

  const validateField = (field: string, value: string) => {
    if (field !== 'description' && !value) {
      setFormErrors({
        ...formErrors,
        [field]: formatMessage({ id: `checklist.create.${field}.error` }),
      });
    } else {
      setFormErrors({
        ...formErrors,
        [field]: '',
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    Object.keys(formErrors).forEach((field) => {
      if (!currentChecklist[field]) {
        isValid = false;
        setFormErrors({
          ...formErrors,
          [field]: formatMessage({ id: `checklist.create.${field}.error` }),
        });
      }
    });
    return isValid;
  };

  const handleRemoveClients = (clientIds: string[]) => {
    const updatedClientIds = currentChecklist.clientIds.filter(
      (id) => !clientIds.includes(id)
    );
    setCurrentChecklist({
      ...currentChecklist,
      clientIds: updatedClientIds,
    });
  };

  const handleAddClients = (clientIds: string[]) => {
    if (currentChecklist) {
      let tempList: string[] = [];
      for (let index = 0; index < 70; index += 1) {
        tempList = tempList.concat(clientIds);
      }
      setCurrentChecklist({ ...currentChecklist, clientIds });
    }
  };

  const handleInputChange = (field: string, value: string) => {
    const updatedChecklist = { ...currentChecklist, [field]: value };
    setCurrentChecklist(updatedChecklist);
    validateField(field, value);
  };

  const handleTypeChange = async (value: Type) => {
    if (value !== 'empty') {
      const path = `../../DefaultChecklists/${value}.json`;
      let groups: ChecklistGroup[];
      if (defaultChecklists[path]) {
        groups = await defaultChecklists[path]().then(
          (module: any) => module.default
        );
      } else {
        throw new Error(`Default checklist not found: ${value}`);
      }
      const updatedChecklist = { ...currentChecklist, type: value, groups };
      setCurrentChecklist(updatedChecklist);
    } else {
      const updatedChecklist = { ...currentChecklist, type: value, groups: [] };
      setCurrentChecklist(updatedChecklist);
    }
    validateField('type', value);
  };

  const getClientIdsOnProgramChange = (value: string): string[] =>
    (value === 'tax_declaration_person' &&
      currentChecklist.program !== 'tax_declaration_person') ||
    (value !== 'tax_declaration_person' &&
      currentChecklist.program === 'tax_declaration_person')
      ? []
      : currentChecklist.clientIds;

  const handleProgramChange = (value: Program) => {
    if (value !== 'reconciliation_fin_statement_company') {
      const updatedGroups = currentChecklist.groups.map((group) => {
        const updatedQuestions = group.questions.map((question) => {
          return {
            ...question,
            fromAccount: undefined,
            toAccount: undefined,
            periodicity:
              value === 'tax_declaration_person'
                ? 'last_period'
                : question.periodicity,
          };
        });
        return {
          ...group,
          fromAccount: undefined,
          toAccount: undefined,
          periodicity:
            value === 'tax_declaration_person'
              ? 'last_period'
              : group.periodicity,
          questions: updatedQuestions,
        };
      });
      setCurrentChecklist({
        ...currentChecklist,
        program: value,
        groups: updatedGroups,
        clientIds: getClientIdsOnProgramChange(value),
      });
    } else {
      setCurrentChecklist({
        ...currentChecklist,
        program: value,
        clientIds: getClientIdsOnProgramChange(value),
      });
    }
    validateField('program', value);
  };

  const handleTreeChange = useCallback((tree: ChecklistGroup[]) => {
    setCurrentChecklist((currentValue) => ({ ...currentValue, groups: tree }));
  }, []);

  const handleSubmit = () => {
    if (validateForm()) {
      onChangeChecklist(currentChecklist);
      trackCustomEvent({
        eventCategory: 'annual_accounts_taxes',
        eventAction: 'checklist:save',
      });
    } else {
      document.querySelector('#checklist_form')?.scrollIntoView();
    }
  };

  const acceptEditMode = () => {
    setShowEditWarningDialog(false);
    setIsEditChecklistTree(true);
  };

  const closeEditWarningDialog = () => {
    setShowEditWarningDialog(false);
  };

  return (
    <>
      <InputsRow id="checklist_form">
        <FormControl error={!!formErrors.type}>
          <Select
            value={currentChecklist.type}
            onChange={(e) => handleTypeChange(e.target.value as Type)}
            label={`${formatMessage({ id: 'checklist.create.type' })} *`}
            labelPlacement="top"
            listItems={TYPES.map((type) => ({
              value: type,
              label: formatMessage({ id: `checklist.type.${type}` }),
              // disable some types yet
              disabled:
                type === 'annual-report' ||
                type === 'tax-declaration' ||
                (type === 'tax' &&
                  !activeFeatureFlags.get(
                    'feature_avstamningChecklistTemplate'
                  )),
            }))}
            width={400}
            editing={!readonly}
          />
          {formErrors.type && (
            <FormHelperText>{formErrors.type}</FormHelperText>
          )}
        </FormControl>
        <FormControl error={!!formErrors.program}>
          <Select
            value={currentChecklist.program}
            onChange={(e) => handleProgramChange(e.target.value as Program)}
            label={`${formatMessage({ id: 'checklist.create.program' })} *`}
            labelPlacement="top"
            listItems={ALL_PROGRAMS.map((item) => ({
              value: item,
              label: formatMessage({ id: `checklist.program.${item}` }),
            }))}
            width={400}
            editing={!readonly}
          />
          {formErrors.program && (
            <FormHelperText>{formErrors.program}</FormHelperText>
          )}
        </FormControl>
      </InputsRow>
      <InputsRow>
        <InputsColumn>
          <InputsColumnItem>
            <TextField
              value={currentChecklist.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              label={`${formatMessage({ id: 'checklist.create.name' })} *`}
              labelPlacement="top"
              width={400}
              editing={!readonly}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </InputsColumnItem>
          <InputsColumnItem>
            <TextField
              value={currentChecklist.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
              label={formatMessage({ id: 'checklist.create.description' })}
              labelPlacement="top"
              width={400}
              editing={!readonly}
            />
          </InputsColumnItem>
        </InputsColumn>
        <ChecklistFormClientSelect
          clientList={
            currentChecklist.program === 'tax_declaration_person'
              ? privateClientList
              : companyClientList
          }
          clientIds={currentChecklist.clientIds}
          handleAddClients={handleAddClients}
          handleRemoveClients={handleRemoveClients}
          editing={!readonly}
        />
      </InputsRow>
      <HeaderContainer>
        <Typography variant="h3">
          {formatMessage({ id: 'checklist.update.group.header' })}
        </Typography>
        {!readonly && currentChecklist.id && !isEditChecklistTree && (
          <Button
            label={formatMessage({ id: 'checklist.update.button.unlock' })}
            variant="text"
            startIcon={<LockOpenOutlined />}
            onClick={() => {
              setShowEditWarningDialog(true);
            }}
          />
        )}
      </HeaderContainer>
      {currentChecklist.id && isEditChecklistTree && showChecklistTreeWarning && (
        <AlertWrapper>
          <Alert
            type="warning"
            onClose={() => {
              setShowChecklistTreeWarning(false);
            }}
          >
            {formatMessage({ id: 'checklist.update.warning.changes' })}
          </Alert>
        </AlertWrapper>
      )}
      <ChecklistTree
        onChange={handleTreeChange}
        groups={currentChecklist.groups}
        showAccountFilters={
          currentChecklist.program === 'reconciliation_fin_statement_company'
        }
        program={currentChecklist.program}
        editing={isEditChecklistTree || !currentChecklist.id}
      />
      <ButtonContainer>
        <Button
          label={formatMessage({ id: 'checklist.create.cancel' })}
          disabled={loading}
          variant="text"
          onClick={onCancel}
        />
        {!readonly && (
          <Button
            label={formatMessage({ id: 'checklist.create.submit' })}
            disabled={loading}
            onClick={handleSubmit}
          />
        )}
      </ButtonContainer>
      <ConfirmationDialog
        isOpen={showEditWarningDialog}
        onAccept={acceptEditMode}
        onClose={closeEditWarningDialog}
        reverseButtonOrder
        showHeaderCloseButton={false}
        title={formatMessage({
          id: 'checklist.warning.dialog.title',
        })}
        alertMessage={formatMessage({
          id: 'checklist.warning.dialog.description',
        })}
        acceptButtonVariant="contained"
        acceptButtonColor="danger"
        acceptButtonStartIcon={<LockOpenOutlined />}
        acceptButtonText={formatMessage({
          id: 'checklist.warning.dialog.button.approve',
        })}
        closeButtonVariant="text"
        closeButtonColor="primary"
        closeButtonText={formatMessage({
          id: 'checklist.warning.dialog.button.cancel',
        })}
      />
    </>
  );
};

export default ChecklistForm;
