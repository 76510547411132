import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';
import appConfig from '_shared/services/config';
import middleware from '_shared/redux/middleware';
import reducer from './reducers';

const devToolsEnchancer = composeWithDevToolsLogOnlyInProduction({
  trace:
    appConfig.runningEnvironment === 'development' ||
    appConfig.runningEnvironment === 'develop',
});

const store = createStore(
  reducer,
  devToolsEnchancer(applyMiddleware(thunk, ...middleware))
);

export default store;
