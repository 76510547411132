/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { UpdateTaxDeclarationFormField } from 'redux/actions';
import { isError, operations, value as getValue } from '@agoy/document';
import { TaxDeclarationFormData } from '@agoy/tax-declaration-document';
import TaxDeclarationViewState from '../types';

const updateStandardField = (
  selectedFormId: string,
  formData: TaxDeclarationViewState['formData'][string],
  state: TaxDeclarationViewState,
  action: UpdateTaxDeclarationFormField
): TaxDeclarationViewState => {
  if (!formData) {
    return state;
  }
  const { partId, id, value } = action;

  const result = id.includes('.table.')
    ? operations.updateCellValue(
        formData.definition,
        formData,
        `${partId}.${id}`,
        getValue(value),
        true
      )
    : operations.updateField(
        formData.definition,
        formData,
        `${partId}.${id}`,
        value
      );

  if (!result || isError(result)) {
    console.warn(result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

const updateTaxDeclarationFormField = (
  state: TaxDeclarationViewState,
  action: UpdateTaxDeclarationFormField
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }
  const { partId } = action;
  return updateStandardField(selectedFormId, formData, state, {
    ...action,
    partId: `${partId}`,
  });
};

export default updateTaxDeclarationFormField;
