import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import {
  SharesCompanyManagementReport,
  EconomicAssociationManagementReport,
  ResultsDisposition as ResultsDispositionType,
} from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import MultilineField from '../../UI/MultilineField';
import { Heading, Section } from '../../../../UI';
import { FormattingContext } from '@agoy/formatting';
import TableSection from './TableSection';

interface ResultsDispositionProps {
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport;
  reportPart: ResultsDispositionType | undefined;
}

const ResultsDisposition = ({
  managementReport,
  reportPart,
}: ResultsDispositionProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const formattingContext = React.useContext(FormattingContext);
  const displayLabel = formattingContext.shouldPrintLabel();

  if (!reportPart) return null;

  const title =
    stringValue(managementReport.resultsDisposition.title) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.resultsDisposition',
    });

  const proposeHeader =
    stringValue(managementReport.resultsDisposition.proposeHeader) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.resultsDisposition.comment',
    });

  const isCommentEmpty = stringValue(reportPart.propose) === '';

  return (
    <Section>
      <Heading title={title} />
      {displayLabel && (
        <Typography variant="h4">
          {formatMessage(
            {
              id: 'annualReport.menu.managementReport.currencyPart',
            },
            {
              type: 'Resultatsdisposition',
            }
          )}
        </Typography>
      )}
      <TableSection
        header={managementReport.resultsDisposition.toDisposeHeader}
        rows={managementReport.resultsDisposition.toDispose.rows}
        sum={managementReport.resultsDisposition.total}
        sumLabel={stringValue(managementReport.resultsDisposition.customTotal)}
      />
      <TableSection
        header={
          managementReport.resultsDisposition.extraordinaryMeetingDividendHeader
        }
        fallbackHeader={formatMessage({
          id: 'annualReport.menu.managementReport.extraordinaryMeetingDividendHeader',
        })}
        rows={
          managementReport.resultsDisposition.extraordinaryMeetingDividend.rows
        }
        sum={
          managementReport.resultsDisposition.extraordinaryMeetingDividendTotal
        }
        sumLabel={stringValue(
          managementReport.resultsDisposition.extraordinaryMeetingDividendTotal
        )}
      />
      <TableSection
        header={managementReport.resultsDisposition.boardProposalHeader}
        fallbackHeader={formatMessage({
          id: 'annualReport.menu.managementReport.boardProposal',
        })}
        rows={managementReport.resultsDisposition.proposal.rows}
        transferLabel={stringValue(
          managementReport.resultsDisposition.customToTransfer
        )}
        transfer={managementReport.resultsDisposition.toTransfer}
        sumLabel={stringValue(
          managementReport.resultsDisposition.customInTotal
        )}
        sum={managementReport.resultsDisposition.inTotal}
      />
      {!isCommentEmpty && (
        <MultilineField
          header={<Typography variant="h4">{proposeHeader}</Typography>}
          field={reportPart.propose}
        />
      )}
    </Section>
  );
};

export default ResultsDisposition;
