const asciiString = new Array(128)
  .fill(0)
  .map((_, i) => String.fromCharCode(i))
  .join('');
const mapUTF8 =
  'ÇüéâäàåçêëèïîìÄÅÉæÆôöòûùÿÖÜ¢£¥₧ƒáíóúñÑªº¿⌐¬½¼¡«»░▒▓│┤╡╢╖╕╣║╗╝╜╛┐└┴┬├─┼╞╟╚╔╩╦╠═╬╧╨╤╥╙╘╒╓╫╪┘┌█▄▌▐▀αßΓπΣσµτΦΘΩδ∞φε∩≡±≥≤⌠⌡÷≈°∙·√ⁿ²■ ';

const decodeBuf = Buffer.from(asciiString + mapUTF8, 'ucs2');

// from iconv-lite
export const convert = (buf) => {
  const newBuf = Buffer.alloc(buf.length * 2);
  for (let i = 0; i < buf.length; i++) {
    const idx1 = buf[i] * 2;
    const idx2 = i * 2;
    newBuf[idx2] = decodeBuf[idx1];
    newBuf[idx2 + 1] = decodeBuf[idx1 + 1];
  }
  return newBuf.toString('ucs2');
};
