import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import {
  id,
  ifOrElse,
  sumAllowNull,
  multiply,
  not,
  ref,
  value,
  formatDate,
  isGreaterThan,
  limitMin,
  isSmallerThan,
} from '@agoy/document';
import {
  mapFormId,
  toInitial,
  toStructure,
  onlyNegative,
  onlyPositive,
} from '../forms-util';
import { getFinancialYearEnd } from '@agoy/dates';
import { getAnnualConfig, getNePersonHelperConfig } from '@agoy/common';

const configNEPerson = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const finYearEnd = getFinancialYearEnd(
    `${financialYearStart}-${financialYearEnd}`
  );

  const finYearEndNumber = parseInt(finYearEnd, 10);

  const year = parseInt(financialYearEnd.slice(0, 4));
  const annualConfig = getAnnualConfig(year);

  const nePersonHelperConfig = getNePersonHelperConfig(finYearEndNumber);

  const { maximalAmount } = nePersonHelperConfig;

  const NE = mapFormId(form, 'NE');
  const initial = {
    NE: {
      partType: 'form' as const,
      id: NE,
      fields: {
        financialYearStart: ref(formatDate(id('NE_COMPANY.NE.sru.7011'))),
        financialYearEnd: ref(formatDate(id('NE_COMPANY.NE.sru.7012'))),
        date: value(''),
        name: value(`${person.firstName} ${person.lastName}`),
        socSecNr: value(person.personNumber),
        scopeOfBusiness: value(''),
        passivNaringsverksamhet: value(false),
        sjalvstandingNaringsverksamhet: value(false),
        inteForenklatBokslut: value(false),
        socSecNrAccountant: value(''),
        B1: ref(id('NE_COMPANY.NE.fields.B1')),
        B2: ref(id('NE_COMPANY.NE.fields.B2')),
        B3: ref(id('NE_COMPANY.NE.fields.B3')),
        B4: ref(id('NE_COMPANY.NE.fields.B4')),
        B5: ref(id('NE_COMPANY.NE.fields.B5')),
        B6: ref(id('NE_COMPANY.NE.fields.B6')),
        B7: ref(id('NE_COMPANY.NE.fields.B7')),
        B8: ref(id('NE_COMPANY.NE.fields.B8')),
        B9: ref(id('NE_COMPANY.NE.fields.B9')),
        B10: ref(id('NE_COMPANY.NE.fields.B10')),
        B11: ref(id('NE_COMPANY.NE.fields.B11')),
        B12: ref(id('NE_COMPANY.NE.fields.B12')),
        B13: ref(id('NE_COMPANY.NE.fields.B13')),
        B14: ref(id('NE_COMPANY.NE.fields.B14')),
        B15: ref(id('NE_COMPANY.NE.fields.B15')),
        B16: ref(id('NE_COMPANY.NE.fields.B16')),
        R1_pos: ref(id('NE_COMPANY.NE.fields.R1_pos')),
        R2_pos: ref(id('NE_COMPANY.NE.fields.R2_pos')),
        R3_pos: ref(id('NE_COMPANY.NE.fields.R3_pos')),
        R4_pos: ref(id('NE_COMPANY.NE.fields.R4_pos')),
        R5_neg: ref(id('NE_COMPANY.NE.fields.R5_neg')),
        R6_neg: ref(id('NE_COMPANY.NE.fields.R6_neg')),
        R7_neg: ref(id('NE_COMPANY.NE.fields.R7_neg')),
        R8_neg: ref(id('NE_COMPANY.NE.fields.R8_neg')),
        R9_neg: ref(id('NE_COMPANY.NE.fields.R9_neg')),
        R10_neg: ref(id('NE_COMPANY.NE.fields.R10_neg')),
        R11: ref(id('NE_COMPANY.NE.fields.R11')),
        uppdragstagareHarBitratt_yes: value(false),
        uppdragstagareHarBitratt_no: value(false),
        R12: ref(id('NE_COMPANY.NE.fields.R12')),
        R13_pos: ref(id('NE_COMPANY.NE.fields.R13_pos')),
        R14_neg: ref(id('NE_COMPANY.NE.fields.R14_neg')),
        R15_pos: ref(id('NE_COMPANY.NE.fields.R15_pos')),
        R16_neg: ref(id('NE_COMPANY.NE.fields.R16_neg')),
        R17: ref(id('NE_COMPANY.NE.fields.R17')),
        R18_neg: ref(id('NE_COMPANY.NE.fields.R18_neg')),
        R19_pos: ref(id('NE_COMPANY.NE.fields.R19_pos')),
        R20_pos: ref(id('NE_COMPANY.NE.fields.R20_pos')),
        R20_neg: ref(id('NE_COMPANY.NE.fields.R20_neg')),
        R21: ref(id('NE_COMPANY.NE.fields.R21')),
        R22_neg: ref(id('NE_COMPANY.NE.fields.R22_neg')),
        R23_pos: ref(id('NE_COMPANY.NE.fields.R23_pos')),
        R24_neg: ref(id('NE_PERSON_PREVIOUS_YEAR.NE.fields.R48')),
        R25_neg: ref(id('NE_COMPANY.NE.fields.R25_neg')),
        R26_pos: ref(id('NE_COMPANY.NE.fields.R26_pos')),
        R27_pos: ref(id('NE_COMPANY.NE.fields.R27_pos')),
        R28_neg: ref(id('NE_COMPANY.NE.fields.R28_neg')),
        R29: ref(id('NE_COMPANY.NE.fields.R29')),
        R30_neg: ref(
          ifOrElse(
            isSmallerThan(
              id('NE.fields.R29'),
              id(
                'NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A16_positivelyUtilized'
              )
            ),
            id('NE.fields.R29'),
            id(
              'NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A16_positivelyUtilized'
            )
          )
        ),
        R31_pos: ref(
          id(
            'NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A15b_negativeDistributionAmount'
          )
        ),
        R32_pos: value(undefined),
        R33: ref(
          sumAllowNull(
            id('NE.fields.R29'),
            multiply(-1, id('NE.fields.R30_neg')),
            id('NE.fields.R31_pos'),
            id('NE.fields.R32_pos')
          )
        ),
        R34_neg: value(undefined),
        R35: ref(
          sumAllowNull(
            id('NE.fields.R33'),
            multiply(-1, id('NE.fields.R34_neg'))
          )
        ),
        R36_neg: ref(
          ifOrElse(
            isSmallerThan(
              id('NE.fields.R35'),
              id(
                'NE_PERSON_HELPER.NE_PERSON_HELPER.fields.B_expansionFundIncrease'
              )
            ),
            id('NE.fields.R35'),
            id(
              'NE_PERSON_HELPER.NE_PERSON_HELPER.fields.B_expansionFundIncrease'
            )
          )
        ),
        R37_pos: ref(
          id(
            'NE_PERSON_HELPER.NE_PERSON_HELPER.fields.B_expansionFundReduction'
          )
        ),
        R38_neg: value(undefined),
        R39_neg: annualConfig
          ? ref(multiply(annualConfig.passiveFee, id('NE.fields.R38_neg')))
          : value(undefined),
        R40_pos: ref(id('NE_PERSON_PREVIOUS_YEAR.NE.fields.R43_neg')),
        R41_neg: value(undefined),
        R42: ref(
          sumAllowNull(
            id('NE.fields.R35'),
            multiply(-1, id('NE.fields.R36_neg')),
            id('NE.fields.R37_pos'),
            multiply(-1, id('NE.fields.R38_neg')),
            multiply(-1, id('NE.fields.R39_neg')),
            id('NE.fields.R40_pos'),
            multiply(-1, id('NE.fields.R41_neg'))
          )
        ),
        R43_neg: ref(id('NE_COMPANY.NE.fields.R43_neg')),
        R44_pos: value(undefined),
        R45_pos: value(undefined),
        R46_pos: value(undefined),
        R47: ref(
          onlyPositive(
            sumAllowNull(
              id('NE.fields.R42'),
              multiply(-1, id('NE.fields.R43_neg')),
              id('NE.fields.R44_pos'),
              id('NE.fields.R45_pos'),
              id('NE.fields.R46_pos')
            )
          )
        ),
        R48: ref(
          onlyNegative(
            sumAllowNull(
              id('NE.fields.R42'),
              multiply(-1, id('NE.fields.R43_neg')),
              id('NE.fields.R44_pos'),
              id('NE.fields.R45_pos'),
              id('NE.fields.R46_pos')
            )
          )
        ),
        otherInfo_1: value(false),
        otherInfo_2: value(undefined),
        otherInfo_3: value(undefined),
        otherInfo_4: value(undefined),
        otherInfo_5: value(undefined),
        otherInfo_6: value(undefined),
        otherInfo_7: value(undefined),
        otherInfo_8: ref(
          ifOrElse(
            isGreaterThan(
              id('NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A12a_excess'),
              maximalAmount
            ),
            id('NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A12a_excess'),
            '0'
          )
        ),
        otherInfo_9: ref(
          ifOrElse(
            isGreaterThan(
              id('NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A12b_deficit'),
              maximalAmount
            ),
            id('NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A12b_deficit'),
            '0'
          )
        ),
        otherInfo_10: ref(
          id(
            'NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A17_savedDistributionAmount'
          )
        ),
        otherInfo_11: ref(
          limitMin(
            id('NE_PERSON_HELPER.NE_PERSON_HELPER.fields.B10a_capitalBasis'),
            '0'
          )
        ),
        companyOrgNumber: ref(id('NE_COMPANY.NE.fields.socSecNr')),
      },
      derivedFields: {
        R47_notPassive: ref(
          ifOrElse(
            not(id('NE.fields.passivNaringsverksamhet')),
            id('NE.fields.R47'),
            undefined
          )
        ),
        R47_isPassive: ref(
          ifOrElse(
            id('NE.fields.passivNaringsverksamhet'),
            id('NE.fields.R47'),
            undefined
          )
        ),
        R48_notPassive: ref(
          ifOrElse(
            not(id('NE.fields.passivNaringsverksamhet')),
            id('NE.fields.R48'),
            undefined
          )
        ),
        R48_isPassive: ref(
          ifOrElse(
            id('NE.fields.passivNaringsverksamhet'),
            id('NE.fields.R48'),
            undefined
          )
        ),
      },
      sru: {
        '7011': ref(id('NE_COMPANY.NE.sru.7011')),
        '7012': ref(id('NE_COMPANY.NE.sru.7012')),
        '7020': ref(id('NE.fields.scopeOfBusiness')),
        '7021': ref(id('NE.fields.passivNaringsverksamhet')),
        '7024': ref(id('NE.fields.sjalvstandingNaringsverksamhet')),
        '7023': ref(id('NE.fields.inteForenklatBokslut')),
        '7025': ref(id('NE.fields.socSecNrAccountant')),
        '7200': ref(id('NE.fields.B1')),
        '7210': ref(id('NE.fields.B2')),
        '7211': ref(id('NE.fields.B3')),
        '7212': ref(id('NE.fields.B4')),
        '7213': ref(id('NE.fields.B5')),
        '7240': ref(id('NE.fields.B6')),
        '7250': ref(id('NE.fields.B7')),
        '7260': ref(id('NE.fields.B8')),
        '7280': ref(id('NE.fields.B9')),
        '7300': ref(id('NE.fields.B10')),
        '7320': ref(id('NE.fields.B11')),
        '7330': ref(id('NE.fields.B12')),
        '7380': ref(id('NE.fields.B13')),
        '7381': ref(id('NE.fields.B14')),
        '7382': ref(id('NE.fields.B15')),
        '7383': ref(id('NE.fields.B16')),
        '7400': ref(id('NE.fields.R1_pos')),
        '7401': ref(id('NE.fields.R2_pos')),
        '7402': ref(id('NE.fields.R3_pos')),
        '7403': ref(id('NE.fields.R4_pos')),
        '7500': ref(id('NE.fields.R5_neg')),
        '7501': ref(id('NE.fields.R6_neg')),
        '7502': ref(id('NE.fields.R7_neg')),
        '7503': ref(id('NE.fields.R8_neg')),
        '7504': ref(id('NE.fields.R9_neg')),
        '7505': ref(id('NE.fields.R10_neg')),
        '7440': ref(id('NE.fields.R11')),
        '8046': ref(id('NE.fields.uppdragstagareHarBitratt_yes')),
        '8047': ref(id('NE.fields.uppdragstagareHarBitratt_no')),
        '7600': ref(id('NE.fields.R12')),
        '7601': ref(id('NE.fields.R13_pos')),
        '7700': ref(id('NE.fields.R14_neg')),
        '7602': ref(id('NE.fields.R15_pos')),
        '7701': ref(id('NE.fields.R16_neg')),
        '7702': ref(id('NE.fields.R18_neg')),
        '7603': ref(id('NE.fields.R19_pos')),
        '7614': ref(id('NE.fields.R20_pos')),
        '7703': ref(id('NE.fields.R20_neg')),
        '7704': ref(id('NE.fields.R22_neg')),
        '7604': ref(id('NE.fields.R23_pos')),
        '7705': ref(id('NE.fields.R24_neg')),
        '7706': ref(id('NE.fields.R25_neg')),
        '7605': ref(id('NE.fields.R26_pos')),
        '7606': ref(id('NE.fields.R27_pos')),
        '7707': ref(id('NE.fields.R28_neg')),
        '7708': ref(id('NE.fields.R30_neg')),
        '7607': ref(id('NE.fields.R31_pos')),
        '7608': ref(id('NE.fields.R32_pos')),
        '7709': ref(id('NE.fields.R34_neg')),
        '7710': ref(id('NE.fields.R36_neg')),
        '7609': ref(id('NE.fields.R37_pos')),
        '7711': ref(id('NE.fields.R38_neg')),
        '7712': ref(id('NE.fields.R39_neg')),
        '7610': ref(id('NE.fields.R40_pos')),
        '7713': ref(id('NE.fields.R41_neg')),
        '7714': ref(id('NE.fields.R43_neg')),
        '7611': ref(id('NE.fields.R44_pos')),
        '7612': ref(id('NE.fields.R45_pos')),
        '7613': ref(id('NE.fields.R46_pos')),
        '7630': ref(id('NE.fields.R47')),
        '7730': ref(id('NE.fields.R48')),
        '8000': ref(id('NE.fields.otherInfo_1')),
        '8002': ref(id('NE.fields.otherInfo_2')),
        '8003': ref(id('NE.fields.otherInfo_3')),
        '8004': ref(id('NE.fields.otherInfo_4')),
        '8006': ref(id('NE.fields.otherInfo_5')),
        '8007': ref(id('NE.fields.otherInfo_6')),
        '8008': ref(id('NE.fields.otherInfo_7')),
        '8009': ref(id('NE.fields.otherInfo_8')),
        '8010': ref(id('NE.fields.otherInfo_9')),
        '8011': ref(id('NE.fields.otherInfo_10')),
        '8012': ref(id('NE.fields.otherInfo_11')),
      },
    },
  };

  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configNEPerson;
