import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { Global } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { createInputData } from 'utils/UserInputUtils';
import PeriodDocumentsProvider from 'utils/usePeriodDocuments/Provider';
import { patchUserInputRoutineData } from '_reconciliation/redux/accounting-view/actions';
import { mapFromSpecification } from '_reconciliation/util/mapSpecification';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import useClientDataLayer from 'data/client/useClientDataLayer';
import { trackCustomEvent } from '@fnox/web-analytics-script';
import { HiddenAccountRow as HiddenRowType } from '../RowContext/types';
import { PeriodDataContextProvider } from './Rows/PeriodDataContext';
import HiddenRowContent from './Rows/HiddenRow';
import HiddenRowsContext from '../RowContext/HiddenRowsContext';

import HiddenRowHistoryProvider from './HiddenRowHistoryProvider';
import { onUserInputRoutineCallbackFunction } from './Rows/types';
import useUserDataWithSubscription from './useUserDataWithSubscription';
import { getFormattedPeriod } from './HiddenGroupRow/utils';

const StyledLoadingPlaceholder = styled(LoadingPlaceholder)`
  height: 460px;
`;

/**
 * A container for the hidden row rendered in the header column.
 * It stretches outside of the column covering the whole row.
 *
 * This is will be moved into a separate component in the next sub tasks...
 *
 * It is positioned 2px up to let the account cell cover the border and create
 * that tab feeling.
 */
const HiddenRowContainer = styled.div`
  position: relative;
  left: 0px;
  width: 100vw;
  transition: height 0.5s; /* Adding a transition for testing height changes */
  overflow-x: visible;

  background-color: ${(props) => props.theme.palette.background.paper};
  z-index: ${(props) => props.theme.zIndex.accountingView.periodRow - 1};
`;

type HiddenRowProps = {
  row: HiddenRowType;
  accountName: string;
  groupId: string;
};

/**
 * Creates the global style for the CSS variable controlling the
 * height of the hidden row.
 *
 * @param row The hidden row
 * @param height The current height of the hidden row
 * @returns
 */
const createStyles = (row, height) => ({
  ':root': {
    [`--${row.id.replace('.', '-')}-height`]: `${height - 2}px`,
  },
});

/**
 * The HiddenRow
 *
 * It renders the content of the hidden row. To communicate the height
 * and changes to its height, an ResizeObserver is observing the content.
 * When an update is made, the height is stored as a global CSS variable
 * with the row.id to identify it.
 */
const HiddenRow = ({ row, accountName, groupId }: HiddenRowProps) => {
  const [styles, setStyles] = useState(createStyles(row, 250));
  const [yearIB, setYearIB] = useState<number | undefined>();
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { removeHiddenRow } = useContext(HiddenRowsContext);

  const data = useUserDataWithSubscription(row);
  const clientDataLayer = useClientDataLayer(row.period.clientId);

  const { currentFinancialYearId } = useSelector((state) => state.customerView);

  const financialYear = row.period.financialYears.find(
    (f) => f.id === currentFinancialYearId
  );

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const contentRect = entries.find(
          (entry) => entry.target === ref.current
        )?.contentRect;

        if (contentRect) {
          window.requestAnimationFrame(() => {
            setStyles(createStyles(row, contentRect.height));
          });
        }
      }),
    [row]
  );

  useEffect(() => {
    trackCustomEvent({
      eventCategory: 'reconciliations',
      eventAction: 'reconciliation_row:expand',
    });
  }, []);

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      observer.observe(element);
      return () => {
        observer.unobserve(element);
      };
    }
  }, [observer]);

  const { periods } = row.period;
  const period = periods[periods.length - 1];

  useEffect(() => {
    if (financialYear) {
      const subscription = clientDataLayer.reconciliation
        .getAccount(
          financialYear.start,
          financialYear.end,
          'financialYear',
          row.accountNumber,
          row.period.type === 'yearEnd'
        )
        .subscribe((result) => {
          if (result.ok) {
            if (result.val) {
              setYearIB(result.val.ib);
            } else {
              // eslint-disable-next-line no-console
              console.warn('account not found');
            }
          }
        });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [row, clientDataLayer.reconciliation, financialYear]);

  // Recreate the old user input format to be able to use the hidden row
  // in both the new and old reconciliation.
  const userData = useMemo(() => {
    if (!data?.userInput && !data?.actualBalance) {
      return undefined;
    }
    const ab = data.actualBalance[period.preliminary ? 'preliminary' : 'final'];
    return {
      ...createInputData({
        ...data.userInput,
        comment: data.userInput?.comment ?? '',
        checked: ab?.checked,
        legacySpecifications:
          data.legacySpecifications.map(mapFromSpecification),
        saldo:
          typeof ab?.balance === 'number' ? ab.balance.toFixed(2) : undefined,
      }),
      ib: row.accountBalance.ib,
      ub: row.accountBalance.ub,
      period: getFormattedPeriod(period),
      periodId: period.id,
      yearIB,
    };
  }, [
    data?.userInput,
    data?.actualBalance,
    data?.legacySpecifications,
    period,
    row.accountBalance,
    yearIB,
  ]);

  const supportUser = useSelector((state) => state.user.supportUser);

  const onUserInput = useCallback(() => {
    // eslint-disable-next-line no-console
    console.warn('This onUserInput should never be called');
  }, []);

  const onUserInputRoutine: onUserInputRoutineCallbackFunction = useCallback(
    (inputData, accountNumber, inPeriod) => {
      if (!supportUser) {
        const formattedPeriod = getFormattedPeriod(inPeriod);
        const routineObj = {};
        routineObj[formattedPeriod] = inputData.routine;
        dispatch(
          patchUserInputRoutineData(row.period.clientId, {
            newUserInput: routineObj,
            accountNumber,
            formattedPeriod,
          })
        );
      }
    },
    [dispatch, row.period.clientId, supportUser]
  );

  const parentPeriod =
    row.period.type === 'dead' ? row.period.parent : undefined;

  return (
    <>
      <Global styles={styles} />
      <HiddenRowContainer ref={ref}>
        {!userData && <StyledLoadingPlaceholder />}
        {period && userData && (
          <HiddenRowHistoryProvider
            account={row.accountNumber}
            accountBalance={row.accountBalance}
            period={row.period}
            inputData={userData}
          >
            <PeriodDataContextProvider
              clientId={row.period.clientId}
              period={row.period.periods}
              periodType={row.period.type}
              lastPeriod={
                parentPeriod &&
                parentPeriod.periods[parentPeriod.periods.length - 1]
              }
              parentPeriodType={
                parentPeriod &&
                (parentPeriod.type === 'quarter' ||
                  parentPeriod.type === 'financialYear')
                  ? parentPeriod.type
                  : undefined
              }
            >
              <PeriodDocumentsProvider account={row.accountNumber}>
                <HiddenRowContent
                  clientId={row.period.clientId}
                  accountName={accountName}
                  accountNumber={row.accountNumber}
                  groupId={groupId}
                  onCloseCallback={() =>
                    removeHiddenRow(row.accountNumber, row.period)
                  }
                  onUserInputCallback={onUserInput}
                  onUserInputRoutineCallback={onUserInputRoutine}
                  period={period}
                  periods={row.period.periods}
                  userData={userData}
                />
              </PeriodDocumentsProvider>
            </PeriodDataContextProvider>
          </HiddenRowHistoryProvider>
        )}
      </HiddenRowContainer>
    </>
  );
};

export default HiddenRow;
