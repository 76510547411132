import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { isActive } from '@agoy/annual-report-document';
import { AgoyTableRow, Field, stringValue } from '@agoy/document';
import Cell from '../../../../UI/CommonPrintTable/Cell/Cell';
import Typography from '../../../../UI/Typography/Typography';

const GridTable = styled.table`
  width: 50%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.light};
  }
`;

interface DisposalSectionProps {
  header: Field;
  sumLabel: string | undefined;
  rows: AgoyTableRow[];
  fallbackHeader?: string;
  sum: Field;
  transferLabel?: string;
  transfer?: Field;
}

const TableSection = ({
  header,
  fallbackHeader,
  rows,
  sumLabel,
  sum,
  transferLabel,
  transfer,
}: DisposalSectionProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  if (!header.active) {
    return null;
  }

  const rowsToDisplay = rows.filter((row) => isActive(row.active));

  if (
    rowsToDisplay.length === 0 &&
    !isActive(sum?.active) &&
    !isActive(transfer?.active)
  ) {
    return null;
  }

  return (
    <>
      {header.active ? (
        <Typography variant="h4">
          {stringValue(header) || fallbackHeader}
        </Typography>
      ) : null}
      <GridTable>
        <thead>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {rowsToDisplay.map(
            (row) =>
              row.cells?.label &&
              row.cells.value && (
                <tr key={row.id}>
                  <td>
                    <Cell cell={row.cells.label} />
                  </td>
                  <td>
                    <Cell cell={row.cells.value} />
                  </td>
                </tr>
              )
          )}

          {transfer?.active && (
            <tr>
              <td>
                <Typography variant="body1" margin="none" textStyle="bold">
                  {transferLabel ||
                    formatMessage({
                      id: 'annualReport.menu.managementReport.toTransfer',
                    })}
                </Typography>
              </td>
              <td>
                <Cell cell={transfer} />
              </td>
            </tr>
          )}

          {sum.active && (
            <tr>
              <td>
                <Typography variant="body1" margin="none" textStyle="bold">
                  {sumLabel ||
                    formatMessage({
                      id: 'sum',
                    })}
                </Typography>
              </td>
              <td>
                <Cell cell={sum} />
              </td>
            </tr>
          )}
        </tbody>
      </GridTable>
    </>
  );
};

export default TableSection;
