import React from 'react';
import {
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { useIntl } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from '@emotion/styled';
import GenericSimplefied, { LabelPlacement } from './GenericSimplefied';

interface SimplifiedSelectProps {
  dataCy?: string;
  listItems: Readonly<
    Array<{
      value: string | number;
      label: string;
      disabled?: boolean;
    }>
  >;
  editing?: boolean;
  labelPlacement?: LabelPlacement;
  menuPlacement?: 'bottom';
  placeholderInput?: string;
  width?: number;
}

const StyledExpandIcon = styled(ExpandMoreIcon)`
  fill: ${(props) => props.theme.palette.grey['900']};
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root.placeholderInput {
    border-radius: ${(props) => props.theme.spacing(1)}px;
    font-size: 14px;

    .MuiSelect-select {
      padding-top: ${(props) => props.theme.spacing(2.5)}px;
      padding-bottom: ${(props) => props.theme.spacing(1)}px;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.placementBottom {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    padding: ${(props) => props.theme.spacing(2)}px;

    &:last-child {
      border-bottom: none;
    },
  }
`;

const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-outlined {
    top: -6px;
    font-size: 14px;
  }

  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, ${(props) => props.theme.spacing(1.5)}px)
      scale(0.65);
  }
`;

const SimplifiedSelect = (props: SelectProps & SimplifiedSelectProps) => {
  const intl = useIntl();
  const {
    dataCy = '',
    listItems,
    editing,
    label,
    className,
    width,
    menuPlacement,
    placeholderInput,
  } = props;
  const inputProps: { 'data-cy'?: string } = {};
  const strippedProps: any = { ...props };

  if (dataCy) {
    inputProps['data-cy'] = dataCy;
    delete strippedProps.dataCy;
  }

  if (props.labelPlacement) {
    delete strippedProps.labelPlacement;
  }

  if (label) {
    delete strippedProps.label;
  }

  if (className) {
    delete strippedProps.className;
  }

  if (menuPlacement) {
    delete strippedProps.menuPlacement;
  }

  if (placeholderInput) {
    delete strippedProps.placeholderInput;
  }

  if (strippedProps.editing) delete strippedProps.editing;
  delete strippedProps.listItems;

  const displayValue =
    listItems?.length &&
    listItems.filter((item) => item.value === props.value)[0]?.label;

  const SelectComponent = (
    <StyledSelect
      labelId={`simplified-select-label-${label}`}
      error={strippedProps.required && !props.value}
      data-cy={dataCy}
      variant="outlined"
      fullWidth={!!width}
      className={placeholderInput ? 'placeholderInput' : ''}
      defaultValue={listItems?.length ? listItems[0].value : ''}
      IconComponent={StyledExpandIcon}
      MenuProps={
        menuPlacement === 'bottom'
          ? {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              MenuListProps: {
                className: 'placementBottom',
                style: {
                  padding: 0,
                },
              },
            }
          : {}
      }
      {...strippedProps}
    >
      {listItems.map((item) => (
        <StyledMenuItem
          className={menuPlacement === 'bottom' ? 'placementBottom' : ''}
          data-cy={dataCy ? `${dataCy}-item-${item.value}` : ''}
          key={item.value}
          value={item.value}
          disabled={!!item.disabled}
        >
          {item.label}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );

  return (
    <GenericSimplefied
      label={label}
      required={strippedProps.required}
      value={displayValue}
      editing={editing}
      labelPlacement={props.labelPlacement}
      width={width}
      className={className}
    >
      {placeholderInput ? (
        <FormControl variant="outlined" fullWidth={!!width}>
          <StyledInputLabel id={`simplified-select-label-${label}`}>
            {placeholderInput}
          </StyledInputLabel>
          {SelectComponent}
          {strippedProps.required && !displayValue && (
            <FormHelperText error>
              {intl.formatMessage({ id: 'form.fieldRequired' })}
            </FormHelperText>
          )}
        </FormControl>
      ) : (
        <>
          {SelectComponent}
          {strippedProps.required && !displayValue && (
            <FormHelperText error>
              {intl.formatMessage({ id: 'form.fieldRequired' })}
            </FormHelperText>
          )}
        </>
      )}
    </GenericSimplefied>
  );
};

export default SimplifiedSelect;
